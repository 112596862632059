<template>
  <!-- start 员工选择对话框 -->
  <el-dialog
    title="员工选择"
    append-to-body
    width="40%"
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCloseDialog"
  >
    <!-- start 自定义内容 -->
    <div class="content-box">
      <el-tabs
        v-model="tabsIndex"
        tab-position="left"
        @tab-click="initCollapse"
      >
        <el-tab-pane
          v-if="item.isSelected"
          v-for="(item, index) in storeList"
          :key="item.StoresGuid"
          :label="item.StoresName"
        >
          <div class="empl-box">
            <el-input
              size="small"
              placeholder="输入员工名称或编号进行搜索"
              v-model="word"
              @input="onSearchEmpl"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>

            <!-- <div class="seletct-box">
              <div
                v-if="dutyItem.EmplList.length > 0"
                class="select-item"
                v-for="(dutyItem, dutyIndex) in item.DutyList"
                :key="dutyIndex"
              >
                <div class="title">
                  <el-checkbox
                    v-model="dutyItem.checkAll"
                    :indeterminate="dutyItem.isIndeterminate"
                    :disabled="!emplMultiple"
                    @change="onChangeCheckAll(dutyItem, index, dutyIndex)"
                  >
                    {{ dutyItem.DutyName }}
                  </el-checkbox>
                </div>

                <div class="col">
                  <template v-for="(emplItem, emplIndex) in dutyItem.EmplList">
                    <el-checkbox
                      v-model="emplItem.check"
                      :true-label="1"
                      :false-label="0"
                      @change="
                        onChangeCheckbox(
                          dutyItem,
                          index,
                          dutyIndex,
                          emplIndex,
                          emplItem
                        )
                      "
                    >
                      {{ `${emplItem.EmplNo} ${emplItem.EmplName}` }}
                    </el-checkbox>
                  </template>
                </div>
              </div>
            </div> -->

            <el-collapse
              class="seletct-box"
              v-model="activeDuty"
              @change="onChangeCollapse"
            >
              <el-collapse-item
                :name="dutyIndex"
                v-if="dutyItem.EmplList.length > 0"
                class="select-item"
                v-for="(dutyItem, dutyIndex) in item.DutyList"
                :key="dutyIndex"
              >
                <template slot="title">
                  <!-- start 职位 -->
                  <div class="title">
                    <el-checkbox
                      v-model="dutyItem.checkAll"
                      :indeterminate="dutyItem.isIndeterminate"
                      :disabled="!emplMultiple"
                      @change="onChangeCheckAll(dutyItem, index, dutyIndex)"
                    >
                      {{ dutyItem.DutyName }}
                    </el-checkbox>
                  </div>
                  <!-- end 职位 -->
                </template>

                <!-- start 员工 -->
                <div class="col">
                  <template v-for="(emplItem, emplIndex) in dutyItem.EmplList">
                    <el-checkbox
                      v-model="emplItem.check"
                      :true-label="1"
                      :false-label="0"
                      @change="
                        onChangeCheckbox(
                          dutyItem,
                          index,
                          dutyIndex,
                          emplIndex,
                          emplItem
                        )
                      "
                    >
                      {{ `${emplItem.EmplNo} ${emplItem.EmplName}` }}
                    </el-checkbox>
                  </template>
                </div>
                <!-- end 员工 -->
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-tab-pane>
      </el-tabs>

      <el-badge
        v-for="(item, index) in badgeList"
        :key="index"
        :value="item.activeNumber || ''"
        :style="
          `position: absolute; top: calc(34px + ${(index + 1) *
            40}px); left: 136px; z-index: 100;`
        "
      >
      </el-badge>
    </div>
    <!-- end 自定义内容 -->

    <!-- start 脚部 -->
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCloseDialog">取 消</el-button>
      <el-button size="small" type="primary" @click="onConfirmData"
        >保 存</el-button
      >
    </span>
    <!-- end 脚部 -->
  </el-dialog>
  <!-- end 员工选择对话框 -->
</template>

<script>
export default {
  props: {
    dataList: {
      // 源数据（只做深拷贝使用）
      type: Array,
      default: () => [],
    },

    menuMultiple: {
      // 菜单是否多选
      type: Boolean,
      default: () => false,
    },

    dutyMultiple: {
      // 职位是否多选
      type: Boolean,
      default: () => false,
    },

    emplMultiple: {
      // 员工是否多选
      type: Boolean,
      default: () => false,
    },
  },

  watch: {
    storeList: {
      handler(newVal) {
        this.badgeList = newVal.filter((e) => e.isSelected);
        console.log(newVal);
      },
      deep: true,
    },
  },

  data() {
    return {
      tabsIndex: 0, // 当前菜单激活下标
      word: "", // 关键词
      activeDuty: [], // 折叠面板激活下标
      storeList: [], // 门店列表
      copyStoreList: [], // 备份门店列表
      badgeList: [], // 徽章列表
      showDialog: false, // 对话框显示
    };
  },

  methods: {
    // 确定按钮点击事件
    async onConfirmData() {
      this.$emit("onConfirm", { storeList: this.storeList });
      this.onCloseDialog();
    },

    // 初始化折叠节点状态
    initCollapse() {
      let storeList = this.storeList,
        copyStoreList = this.copyStoreList;
      let arr = [];

      if(!storeList.length){
        return
      }

      storeList[this.tabsIndex].DutyList.forEach((e, i) => {
        if (e.isCollapse !== false) {
          e.isCollapse = true;
          copyStoreList[this.tabsIndex].DutyList[i].isCollapse = true
          arr.push(i);
        }
      });
      this.activeDuty = arr;
      // console.log(this.activeDuty);
    },

    // 折叠节点改变事件
    onChangeCollapse(event) {
      console.log(event);
      let storeList = this.storeList;
      let copyStoreList = this.copyStoreList;

      event.forEach((item) => {
        for (let i = 0; i < storeList[this.tabsIndex].DutyList.length; i++) {
          storeList[this.tabsIndex].DutyList[i].isCollapse = true;
          copyStoreList[this.tabsIndex].DutyList[i].isCollapse = true;
          if (item != i) {
            storeList[this.tabsIndex].DutyList[i].isCollapse = false;
            copyStoreList[this.tabsIndex].DutyList[i].isCollapse = false;
          }
        }
      });
    },

    // 多选框点击事件
    onChangeCheckbox(dutyItem, storeIndex, dutyIndex, emplIndex, emplItem) {
      let storeList = JSON.parse(JSON.stringify(this.storeList)),
        copyStoreList = JSON.parse(JSON.stringify(this.copyStoreList));

      if (!this.menuMultiple) {
        copyStoreList.forEach((storeItem2, storeIndex2) => {
          if (storeIndex2 != storeIndex && storeItem2.activeNumber) {
            storeItem2.activeNumber = 0;
            storeItem2.DutyList.forEach((dutyItem2, dutyIndex2) => {
              if (dutyItem2.checkAll || dutyItem2.isIndeterminate) {
                dutyItem2.EmplList.forEach((emplItem2, emplIndex2) => {
                  emplItem2.check ? (emplItem2.check = 0) : null;
                });
              }
            });
          }
        });
        storeList = JSON.parse(JSON.stringify(copyStoreList));
      }

      let dutyList = storeList[storeIndex].DutyList,
        copyDutyList = copyStoreList[storeIndex].DutyList;

      let sum = 0,
        oldIndex = 0;

      copyDutyList.forEach((dutyItem2, dutyIndex2) => {
        if (!this.dutyMultiple && dutyItem.DutyNo != dutyItem2.DutyNo) {
          dutyItem2.EmplList.forEach((e) => (e.check = false));
        }

        if (!this.emplMultiple && dutyItem.DutyNo == dutyItem2.DutyNo) {
          dutyItem2.EmplList.find((e, i) => {
            if (e.check) {
              return (e.check = 0);
            }
          });
        }

        if (dutyItem.DutyNo == dutyItem2.DutyNo) {
          // copyDutyList[dutyIndex2].EmplList.find((e, i) => {
          //   if (e.Key == emplItem.Key) {
          //     return (e.check = emplItem.check);
          //   }
          // });
          storeList[storeIndex].DutyList[dutyIndex].EmplList[emplIndex].check =
            emplItem.check;
          copyStoreList[storeIndex].DutyList[dutyIndex].EmplList[
            emplIndex
          ].check = emplItem.check;
        }

        let checkLength = dutyItem.EmplList.filter(
          (emplItem2) => emplItem2.check
        ).length;
        let emplLength = dutyItem.EmplList.length;

        dutyList[dutyIndex].isIndeterminate =
          checkLength > 0 && checkLength < emplLength ? true : false;
        dutyList[dutyIndex].checkAll = checkLength == emplLength ? true : false;

        checkLength = dutyItem2.EmplList.filter((emplItem2) => emplItem2.check)
          .length;
        emplLength = dutyItem2.EmplList.length;

        dutyItem2.isIndeterminate =
          checkLength > 0 && checkLength < emplLength ? true : false;
        dutyItem2.checkAll = checkLength == emplLength ? true : false;

        if (dutyItem2.isIndeterminate && !dutyItem2.checkAll) {
          dutyItem2.EmplList.forEach((emplItem2) => {
            emplItem2.check ? sum++ : "";
            // console.log(emplItem, sum);
          });
        } else if (!dutyItem2.isIndeterminate && dutyItem2.checkAll) {
          sum += dutyItem2.EmplList.length;
        }

        if (dutyIndex2 == copyDutyList.length - 1) {
          storeList[storeIndex].activeNumber = sum;
          copyStoreList[storeIndex].activeNumber = sum;

          storeList[storeIndex].DutyList = copyDutyList;
          copyStoreList[storeIndex].DutyList = copyDutyList;

          this.storeList = storeList;
          this.copyStoreList = copyStoreList;
          // console.log(storeList, copyStoreList);
        }
      });
    },

    // 全选框点击事件
    onChangeCheckAll(dutyItem, storeIndex, dutyIndex) {
      let storeList = JSON.parse(JSON.stringify(this.storeList)),
        copyStoreList = JSON.parse(JSON.stringify(this.copyStoreList));

      let dutyList = storeList[storeIndex].DutyList,
        copyDutyList = copyStoreList[storeIndex].DutyList;

      let sum = 0;
      copyDutyList.forEach((dutyItem2, dutyIndex2) => {
        if (dutyItem.DutyNo == dutyItem2.DutyNo) {
          dutyList[dutyIndex].EmplList.forEach((item, index) => {
            item.check = dutyItem.checkAll ? 1 : 0;

            dutyItem2.EmplList.find((e) => {
              if (e.Key == item.Key) {
                return (e.check = item.check);
              }
            });
          });

          // let checkLength = dutyItem2.EmplList.filter(
          //   (emplItem) => emplItem.check
          // ).length;
          // let emplLength = dutyItem2.EmplList.length;

          dutyList[dutyIndex].isIndeterminate = false;
          dutyItem2.isIndeterminate = false;
          dutyItem2.checkAll = dutyList[dutyIndex].checkAll;
        }

        if (dutyItem2.isIndeterminate && !dutyItem2.checkAll) {
          dutyItem2.EmplList.forEach((emplItem) => {
            emplItem.check ? sum++ : "";
            // console.log(emplItem, sum);
          });
        } else if (!dutyItem2.isIndeterminate && dutyItem2.checkAll) {
          sum += dutyItem2.EmplList.length;
        }

        if (dutyIndex2 == copyDutyList.length - 1) {
          storeList[storeIndex].activeNumber = sum;
          copyStoreList[storeIndex].activeNumber = sum;
          this.storeList = storeList;
          this.copyStoreList = copyStoreList;
          // console.log(storeList, copyStoreList);
        }
      });
    },

    // 关键词查询
    onSearchEmpl() {
      let { word, copyStoreList, tabsIndex } = this;
      let storeList = JSON.parse(JSON.stringify(this.storeList));
      let reg = new RegExp(word, "i");
      copyStoreList[tabsIndex].DutyList.forEach((dutyItem, dutyIndex) => {
        let arr = dutyItem.EmplList.filter((emplItem, emplIndex) =>
          reg.test(emplItem.EmplName)
        );
        storeList[tabsIndex].DutyList[dutyIndex].EmplList = arr;
      });
      this.storeList = storeList;
      console.log(storeList);
    },

    // 打开对话框
    onShowDialog() {
      this.storeList = JSON.parse(JSON.stringify(this.dataList));
      this.copyStoreList = JSON.parse(JSON.stringify(this.dataList));
      this.initCollapse();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetSelectedData();
      this.showDialog = false;
    },

    // 重置数据
    resetSelectedData() {
      this.storeList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  height: 40vh;

  ::v-deep .el-tabs {
    position: relative;
    height: 100%;

    .el-tabs__header {
      width: 150px;
      .el-tabs__item {
        max-width: 140px;
        padding-left: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }

    .empl-box {
      height: 100%;
      width: 100%;

      .seletct-box {
        height: 35vh;
        margin-top: 20px;
        overflow-y: scroll;
        .select-item {
          .title {
            margin-top: 20px;
            margin-bottom: 10px;

            .el-checkbox__label {
              color: #000000;
              font-weight: bold;
            }
          }

          .col {
            .flex-row();
            flex-wrap: wrap;

            .el-checkbox {
              width: 25%;
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
}
</style>
