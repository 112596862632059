import {
	request
} from "../utils/axios.js";
export default {
	// 会员 ==> 会员分类
	getMemberkindlist(params = {}) {
		return request('get', `/member/get_memberkindlist`, params);
	},

	getMemberTagSelector(params = {}) {
		return request('get', `/member/get_member_tag_selector`, params);
	},

	addMemberkind(params = {}) {
		return request('post', `/member/add_memberkind`, params);
	},

	updateMemberkind(params = {}) {
		return request('post', `/member/update_memberkind`, params);
	},

	deleteMemberkind(params = {}) {
		return request('get', `/member/delete_memberkind`, params);
	},

	deleteMemberTagKind(params = {}) {
		return request('get', `/member/delete_member_tag_kind`, params);
	},

	saveMemberTagKind(params = {}) {
		return request('post', `/member/save_member_tag_kind`, params);
	},

	// 会员 ==> 维度分类
	getDimensionSelector(params = {}) {
		return request('get', `/member/get_dimension_selector`, params);
	},

	initDimensionEditor(params = {}) {
		return request('get', `/member/init_dimension_editor`, params);
	},

	saveDimensionEditor(params = {}) {
		return request('post', `/member/save_dimension_editor`, params);
	},

	sortAllDimension(params = {}) {
		return request('post', `/member/sort_all_dimension`, params);
	},

	deleteDimension(params = {}) {
		return request('get', `/member/delete_dimension`, params);
	},

	clearDimensionKindMaps(params = {}) {
		return request('get', `/member/clear_dimension_kind_maps`, params);
	},

	calcuDimensionKindMaps(params = {}) {
		return request('get', `/member/calcu_dimension_kind_maps`, params);
	},

	// 会员 ==> 标签管理
	getMembertaglist(params = {}) {
		return request('get', `/member/get_membertaglist`, params);
	},

	addMembertag(params = {}) {
		return request('post', `/member/add_membertag`, params);
	},

	updateMembertag(params = {}) {
		return request('post', `/member/update_membertag`, params);
	},

	deleteMembertag(params = {}) {
		return request('get', `/member/delete_membertag`, params);
	},

	//会员 => 会员查询
	getTotalBalance(params = {}) {
		return request('get', `/member/get_total_balance`, params);
	},

	getSelectInitdata(params = {}) {
		return request('get', `/member/get_select_initdata`, params);
	},

	initSaveMemberData(params = {}) {
		return request('get', `/member/init_save_member_data`, params);
	},

	saveMember(params = {}, subact) {
		return request('post', `/member/save_member?subact=${subact}`, params);
	},

	deleteMember(params = {}) {
		return request('get', `/member/delete_member`, params);
	},

	saveMemberPicture(params = {}) {
		return request('post', `/member/save_member_picture`, params);
	},

	deleteMemberPricture(params = {}) {
		return request('get', `/member/delete_member_pricture`, params);
	},

	memberMaintSettingFetchCustom(params = {}) {
		return request('get', `/member/member_maint_setting`, params);
	},

	memberMaintSettingSaveCustom(params = {}) {
		return request('post', `/member/member_maint_setting?subact=save_custom`, params);
	},

	getMemberMaintList(params = {}) {
		return request('post', `/member/get_member_maint_list`, params);
	},

	initMemberMaintQuery(params = {}) {
		return request('post', `/member/init_member_maint_query`, params);
	},

	initSaveMemberData(params = {}) {
		return request('get', `/member/init_save_member_data`, params);
	},

	memberVipList(params = {}) {
		return request('get', `/member/get_member_vip_list`, params);
	},

	getExpeKeepRecords(params = {}) {
		return request('get', `/member/get_expe_keep_records`, params);
	},

	getMemberCouponList(params = {}) {
		return request('get', `/member/get_member_coupon_list`, params);
	},

	getMemberInteRecordList(params = {}) {
		return request('get', `/member/get_inte_record_list`, params);
	},

	querySelector(params = {}) {
		return request('get', `/member/query_selector`, params);
	},

	restoreMember(params = {}) {
		return request('get', `/member/restore_member`, params);
	},

	disableMember(params = {}) {
		return request('get', `/member/disable_member`, params);
	},

	setKindBatch(params = {}) {
		return request('post', `/member/set_kind_batch`, params);
	},

	resetWechatMark(params = {}) {
		return request('get', `/member/reset_wechat_mark`, params);
	},

	// 会员 ===> 概要分析
	getSummaryReport(params = {}) {
		return request('get', `/member/get_summary_report`, params);
	},

	// 会员 ===> 顾客来源
	getClientSource(params = {}) {
	    return request('get', `/member/get_client_source`, params);
	},

	saveClientSource(params = {}) {
	    return request('get', `/member/save_client_source`, params);
	},

	deleteClientSource(params = {}) {
	    return request('get', `/member/delete_client_source`, params);
	},

}