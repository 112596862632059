import { request } from "../utils/axios"

export default {
	// 商城 ===> 商品分类
	getCategoryList(params = {}) {
		return request('get', `/shop/get_category_list`, params);
	},

	saveCategory(params = {}) {
		return request('post', `/shop/save_category`, params);
	},

    deleteCategory(params = {}) {
		return request('get', `/shop/delete_category`, params);
	},

    // 商城 ===> 商品列表
	initItemMaintList(params = {}) {
		return request('get', `/shop/init_item_maint_list`, params);
	},

    getItemMaintList(params = {}) {
		return request('get', `/shop/get_item_maint_list`, params);
	},

	initItem(params = {}) {
		return request('get', `/shop/init_item`, params);
	},

	getValidStoreList(params = {}) {
		return request('get', `/shop/get_valid_store_list`, params);
	},

	addAttrKey(params = {}) {
		return request('get', `/shop/add_attr_key`, params);
	},

	addAttrValue(params = {}) {
		return request('get', `/shop/add_attr_value`, params);
	},

	saveItem(params = {}) {
		return request('post', `/shop/save_item`, params);
	},

	deleteItem(params = {}) {
		return request('get', `/shop/delete_item`, params);
	},

    // 商城 ===> 拼团商品
	getGrouponSettingMaintList(params = {}) {
		return request('get', `/shop/get_groupon_setting_maint_list`, params);
	},

	initGrouponSetting(params = {}) {
		return request('get', `/shop/init_groupon_setting`, params);
	},

	initGrouponPriceList(params = {}) {
		return request('get', `/shop/init_groupon_price_list`, params);
	},

	saveGrouponSetting(params = {}) {
		return request('post', `/shop/save_groupon_setting`, params);
	},

	deleteGrouponPrice(params = {}) {
		return request('get', `/shop/delete_groupon_price`, params);
	},

	deleteGrouponSetting(params = {}) {
		return request('get', `/shop/delete_groupon_setting`, params);
	},

	// 商城 ===> 订单管理
	initOrderMaintList(params = {}) {
		return request('get', `/shop/init_order_maint_list`, params);
	},

	getOrderMaintList(params = {}) {
		return request('get', `/shop/get_order_maint_list`, params);
	},

	getOrderMaintDetail(params = {}) {
		return request('get', `/shop/get_order_maint_detail`, params);
	},

	getVerifierSelector(params = {}) {
		return request('get', `/shop/get_verifier_selector`, params);
	},

	verifyOrder(params = {}) {
		return request('get', `/shop/verify_order`, params);
	},

	auditCanceling(params = {}) {
		return request('get', `/shop/audit_canceling`, params);
	},

	refundOrder(params = {}) {
		return request('get', `/shop/refund_order`, params);
	},

	fixSqbPayOrder(params = {}) {
		return request('get', `/shop/fix_sqb_pay_order`, params, true, true, false, false , 120000);
	},

	auditAftersale(params = {}) {
		return request('get', `/shop/audit_aftersale`, params);
	},

	forceAftersale(params = {}) {
		return request('get', `/shop/force_aftersale`, params);
	},

	// 商城 ===> 拼团记录
	getGrouponRecordList(params = {}) {
		return request('get', `/shop/get_groupon_maint_list`, params);
	},

	getGrouponPartnerList(params = {}) {
		return request('get', `/shop/get_groupon_partner_list`, params);
	},

	handleTimeoutGroupon(params = {}) {
		return request('get', `/shop/handle_timeout_groupon`, params);
	},

	// 商城 ===> 基础设置
	initBasicSetting(params = {}) {
		return request('get', `/shop/init_basic_setting`, params);
	},

	saveBasicSetting(params = {}) {
		return request('post', `/shop/save_basic_setting`, params);
	},

	// 商城 ===> 主页设置
	initHomeSetting(params = {}) {
		return request('get', `/shop/init_home_setting`, params);
	},

	saveHomeSetting(params = {}) {
		return request('post', `/shop/save_home_setting`, params);
	},

	// 商城 ===> 销售关系
	getSalesRefereeList(params = {}) {
		return request('get', `/shop/get_sales_referee_list`, params);
	},

	deleteSalesReferee(params = {}) {
		return request('get', `/shop/delete_sales_referee`, params);
	},

	addSalesReferee(params = {}) {
		return request('get', `/shop/add_sales_referee`, params);
	},

	// 商城 ===> 数据统计
	getEmployeeStatis(params = {}) {
		return request('get', `/shop/get_employee_statis`, params);
	},

	getSummaryStatis(params = {}) {
		return request('get', `/shop/get_summary_statis`, params);
	},
}