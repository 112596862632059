export default { 
	path: '/customer',
	name: 'Customer',
    redirect: '/customer/manage',
	meta:{
		title: '客情管理',
		keepAlive: true
	},
	component: ()=> import('../views/customer/index.vue'),
	children:[
		{
			path: 'manage',
			name: 'Manage',
			meta:{
				title: '客情管理',
				keepAlive: true
			},
			component: ()=> import('../views/customer/manage/manage.vue'),
		},
	]
}