export default {
  path: "/employee",
  name: "Employee",
  redirect: "/employee/staff",
  meta: {
    title: "员工",
    keepAlive: true,
  },
  component: () => import("../views/employee/index.vue"),
  children: [
    {
      path: "staff",
      name: "Staff",
      meta: {
        title: "员工",
        keepAlive: true,
      },
      component: () => import("../views/employee/staff/list.vue"),
    },
    {
      path: "jobs",
      name: "Jobs",
      meta: {
        title: "岗位设置",
        keepAlive: true,
      },
      component: () => import("../views/employee/jobs/index.vue"),
    },
    {
      path: "department",
      name: "Department",
      meta: {
        title: "部门设置",
        keepAlive: true,
      },
      component: () => import("../views/employee/department/index.vue"),
    },
    {
      path: "attendance",
      name: "Attendance",
      redirect: "/employee/attendance/work-detail",
      meta: {
        title: "考勤",
        keepAlive: true,
      },
      component: () => import("../views/employee/attendance/index.vue"),
      children: [
        {
          path: "work-statistics",
          name: "WorkStatistics",
          meta: {
            title: "员工考勤统计表",
            keepAlive: true,
          },
          component: () =>
            import("../views/employee/attendance/work/statistics.vue"),
        },
        {
          path: "work-detail",
          name: "WorkDetail",
          meta: {
            title: "员工考勤明细表",
            keepAlive: true,
          },
          component: () =>
            import("../views/employee/attendance/work/detail.vue"),
        },
      ],
    },
  ],
};
