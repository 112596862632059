<template>
  <div class="df-goods-select">
    <span>推荐购买的商品:</span>

    <!-- 提示弹出框 -->
    <el-popover placement="right" width="340" trigger="hover">
      <div class="content">
        <div>
          进行顾客服务回访时，
        </div>
        <div>
          管理员或员工可以在美管加APP上邀请顾客购买以下类型的推荐商品
        </div>
      </div>

      <i class="el-icon-warning-outline" slot="reference"></i>
    </el-popover>

    <!-- 选择消费项目 -->
    <div class="select-goods">
      <div>
        <span v-for="(item, index) in selectItems" :key="index">{{
          `${item.ItemName}${index == selectItems.length - 1 ? "" : "、"}`
        }}</span>
      </div>

      <span class="click" @click="onShowGoodsSelect">+ 选择商品</span>
    </div>

    <!-- 选择商品(消费项目)对话框 -->
    <DfGoodsDialog
      ref="DfGoodsDialog"
      @onConfirm="onSelectItems"
    ></DfGoodsDialog>
  </div>
</template>

<script>
import DfGoodsDialog from "./df-goods-dialog.vue";

export default {
  components: { DfGoodsDialog },

  data() {
    return {
      selectItems: [],  // 选中的消费项目数据
    };
  },

  methods: {
    // 打开选择商品对话框
    onShowGoodsSelect() {
      this.$refs.DfGoodsDialog.onShowDialog(this.selectItems);
    },

    // 确定选中商品事件
    onSelectItems(event) {
      this.selectItems = event.selectItems;
    },
  },
};
</script>

<style lang="less" scoped>
.df-goods-select {
  display: flex;
  flex-direction: row;
  // margin: 14px 0;
  margin: 0;
  line-height: unset;

  .select-goods {
  display: flex;
  flex-direction: row;
  padding: 0 30px;
    .click {
      padding-left: 10px;
      color: #409eff;
      cursor: pointer;
    }

    .click:hover {
      color: #66b1ff;
    }
  }
}

.market-i(3px);

.market-popover();
</style>
