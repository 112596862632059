export default {
  path: "/setting",
  name: "setting",
  redirect: "/setting/admin",
  meta: {
    title: "设置",
    keepAlive: true,
  },
  component: () => import("../views/setting/index.vue"),
  children: [
    {
      path: "basic",
      name: "Basic",
      redirect: "/setting/basic/discount",
      meta: {
        title: "基础设置",
        keepAlive: true,
      },
      component: () => import("../views/setting/basic/index.vue"),
      children: [
        {
          path: "discount",
          name: "Discount",
          meta: {
            title: "折扣比例",
            keepAlive: true,
          },
          component: () => import("../views/setting/basic/discount/discount.vue"),
        },
        {
          path: "monad",
          name: "Monad",
          meta: {
            title: "单位设置",
            keepAlive: true,
          },
          component: () => import("../views/setting/basic/monad/monad.vue"),
        },
        {
          path: "billNo",
          name: "BillNo",
          meta: {
            title: "水单号设置",
            keepAlive: true,
          },
          component: () =>
            import("../views/setting/basic/bill-no/bill-no.vue"),
        },
        {
          path: "template",
          name: "Template",
          meta: {
            title: "消息模板",
            keepAlive: true,
          },
          component: () => import("../views/setting/basic/template/template.vue"),
        },
        {
          path: "room",
          name: "Room",
          meta: {
            title: "房间管理",
            keepAlive: true,
          },
          component: () => import("../views/setting/basic/room/room.vue"),
        },
        {
          path: "parameter",
          name: "Parameter",
          meta: {
            title: "基础参数",
            keepAlive: true,
          },
          component: () =>
            import("../views/setting/basic/parameter/parameter.vue"),
        }
      ],
    },
    {
      path: "notice",
      name: "Notice",
      meta: {
        title: "营销设置",
        keepAlive: true,
      },
      redirect: "/setting/notice/send",
      component: () => import("../views/setting/notice/notice.vue"),
      children: [
        {
          path: "send",
          name: "Send",
          meta: {
            title: "短信设置",
            keepAlive: true,
          },
          component: () => import("../views/setting/notice/send/send.vue"),
        },
        {
          path: "evaluate",
          name: "Evaluate",
          meta: {
            title: "短信设置",
            keepAlive: true,
          },
          component: () =>
            import("../views/setting/notice/evaluate/evaluate.vue"),
        },
      ],
    },
    {
      path: "applet",
      name: "Applet",
      meta: {
        title: "小程序",
        keepAlive: true,
      },
      redirect: "/setting/applet/basis",
      component: () => import("../views/setting/applet/index.vue"),
      children: [
        {
          path: "basis",
          name: "Basis",
          meta: {
            title: "基础设置",
            keepAlive: true,
          },
          component: () => import("../views/setting/applet/basis/basis.vue"),
        },
        {
          path: "wifi",
          name: "Wifi",
          meta: {
            title: "WIFI设置",
            keepAlive: true,
          },
          component: () => import("../views/setting/applet/wifi/wifi.vue"),
        },
        {
          path: "storeQrCode",
          name: "StoreQrCode",
          meta: {
            title: "门店二维码设置",
            keepAlive: true,
          },
          component: () =>
            import("../views/setting/applet/storeQrcode/storeQrcode.vue"),
        },
        {
          path: "page",
          name: "Page",
          redirect: "/setting/applet/page/management",
          meta: {
            title: "页面管理",
            keepAlive: true,
          },
          component: () => import("../views/setting/applet/page/list.vue"),
          children: [
            {
              path: "management",
              name: "Management",
              meta: {
                title: "页面管理",
                keepAlive: true,
              },
              component: () =>
                import(
                  "../views/setting/applet/page/management/management.vue"
                ),
            },
            {
              path: "design",
              name: "Design",
              meta: {
                title: "页面设计",
                keepAlive: true,
              },
              component: () =>
                import("../views/setting/applet/page/design/design.vue"),
            },
          ],
        },
        {
          path: "feature",
          name: "Feature",
          meta: {
            title: "功能设置",
            keepAlive: true,
          },
          component: () =>
            import("../views/setting/applet/feature/feature.vue"),
        },
        {
          path: "global",
          name: "Global",
          meta: {
            title: "全局设置",
            keepAlive: true,
          },
          component: () => import("../views/setting/applet/global/global.vue"),
        },
      ],
    },
    {
      path: "app",
      name: "App",
      meta: {
        title: "app设置",
        keepAlive: true,
      },
      redirect: "/setting/app/attendance",
      component: () => import("../views/setting/app/index.vue"),
      children: [
        {
          path: "attendance",
          name: "Attendance",
          meta: {
            title: "二维码展示",
            keepAlive: true,
          },
          component: () =>
            import("../views/setting/app/attendance/attendance.vue"),
        },
        {
          path: "basis",
          name: "AppBasis",
          meta: {
            title: "基础",
            keepAlive: true,
          },
          component: () => import("../views/setting/app/basis/basis.vue"),
        },
      ],
    },
    {
      path: "admin",
      name: "Admin",
      meta: {
        title: "管理员",
        keepAlive: true,
      },
      component: () => import("../views/setting/admin/admin.vue"),
    },
    {
      path: "log",
      name: "Log",
      meta: {
        title: "日志",
        keepAlive: true,
      },
      component: () => import("../views/setting/log/log.vue"),
    },
  ],
};
