<template>
  <div class="df-member-card-select">
    <!-- start 会员卡选择对话框 -->
    <el-dialog
      title="选择会员卡"
      :visible.sync="showDialog"
      width="60%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <!-- start 自定义内容 -->
      <div class="df-member-card-dialog">
        <div
          class="df-row"
          v-show="!submitData.member_guid && !submitData.nature_no"
        >
          <!-- 查询条件 -->
          <div class="df-col">
            <el-select
              v-model="submitData.type"
              placeholder="请选择"
              size="mini"
            >
              <el-option
                v-for="item in typeList"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>

          <!-- 查询精度 -->
          <div class="df-col">
            <el-select
              v-model="submitData.precise"
              placeholder="请选择"
              size="mini"
            >
              <el-option
                v-for="item in preciseList"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>

          <!-- 关键字输入框 -->
          <div class="df-col">
            <el-input
              v-model="submitData.word"
              size="mini"
              clearable
            ></el-input>
          </div>

          <!-- 查询按钮 -->
          <div class="df-col">
            <el-button type="primary" size="mini" @click="onSearch"
              >查询</el-button
            >
          </div>
        </div>

        <el-table :data="dataList" max-height="460" size="mini" border stripe>
          <el-table-column prop="MemberName" label="会员名称" width="120px">
          </el-table-column>
          <el-table-column prop="MemberPhone" label="会员电话" width="120px">
          </el-table-column>
          <el-table-column prop="NatureName" label="卡属性"> </el-table-column>
          <el-table-column prop="NatureNo" label="卡类型"> </el-table-column>
          <el-table-column prop="VipNo" label="会员卡号" width="110">
          </el-table-column>
          <el-table-column prop="KeepBalance" label="充值余额">
          </el-table-column>
          <el-table-column prop="DonaBalance" label="赠送余额">
          </el-table-column>
          <el-table-column prop="PackBalance" label="总消耗额">
          </el-table-column>
          <el-table-column prop="InteBalance" label="积分"> </el-table-column>
          <el-table-column prop="DebtBalance" label="欠款"> </el-table-column>
          <el-table-column label="有效期" width="140">
            <template slot-scope="scoped">
              {{
                !scoped.row.ValidDate
                  ? "无限期"
                  : util.dateFormat(
                      "YYYY-mm-dd HH:MM:SS",
                      new Date(scoped.row.ValidDate * 1000)
                    )
              }}
            </template>
          </el-table-column>
          <el-table-column prop="VipNote" label="备注" width="160">
          </el-table-column>
          <el-table-column prop="StoresName" label="所属门店" width="180">
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scoped">
              <el-button
                type="primary"
                size="mini"
                @click="onSelectItem(scoped.row)"
                >选择</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- end 自定义内容 -->

      <!-- start 脚部 -->
      <span slot="footer" class="dialog-footer">
        <!-- start 分页器 -->
        <el-pagination
          background
          :page-sizes="[10, 15, 20, 25]"
          :page-size="submitData.page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalRecord"
          :pager-count="5"
          size="small"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
        <!-- end 分页器 -->

        <div class="btn-box">
          <el-button size="small" @click="onCloseDialog">取 消</el-button>
        </div>
      </span>
      <!-- end 脚部 -->
    </el-dialog>
    <!-- end 会员卡选择对话框 -->
  </div>
</template>

<script>
import Vip from "@/api/vip.js";

export default {
  props: {
    memberGuid: {
      type: String,
      default: "",
    },
    natureNo: {
      type: String,
      default: "",
    },
  },

  watch: {
    memberGuid: {
      handler(val) {
        this.submitData.member_guid = val;
      },
      immediate: true,
    },

    natureNo: {
      handler(val) {
        this.submitData.nature_no = val;
      },
      immediate: true,
    },
  },

  data() {
    return {
      totalRecord: 0,
      submitData: {
        page_index: 1,
        page_size: 10,
        type: 1,
        precise: 0,
        word: "",
        member_guid: "",
        nature_no: "",
      },
      typeList: [],
      preciseList: [],
      dataList: [],
      showDialog: false,
      loading: false,
    };
  },

  methods: {
    // 初始化通用会员卡查询
    async initQuerySelector() {
      try {
        let { data } = await Vip.initQuerySelector();
        this.typeList = data.typeList;
        this.preciseList = data.preciseList;
      } catch (err) {
        console.log(err);
      }
    },

    // 查询通用会员卡
    async getQuerySelector() {
      this.loading = true;
      try {
        let { data } = await Vip.getQuerySelector(this.submitData);
        let { filter, list } = data;

        this.totalRecord = filter.TotalRecord;
        this.dataList = list;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 查询按钮点击事件
    onSearch() {
      this.submitData.page_index = 1;
      this.getQuerySelector();
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getQuerySelector();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getQuerySelector();
    },

    // 选择按钮点击事件
    onSelectItem(event) {
      this.$emit("select", event);
      this.onCloseDialog();
    },

    // 打开对话框
    onShowDialog() {
      this.$nextTick(() => {
        this.initQuerySelector();
        this.getQuerySelector();
        this.showDialog = true;
      });
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  .flex-row;
  justify-content: space-between;
}
</style>
