import { request } from "../utils/axios"

export default {
	// 获取图片分组列表
	getGroupList(params = {}) {
		return request("get", "/media/get_group_list", params)
	},

	// 获取图片列表
	getMediaList(params = {}){
		return request("get", "/media/get_media_list", params)
	},

	// 上传图片文件
	uploadFile(params = {}){
		return request("post", "/media/upload_file", params)
	},

	// 删除图片
	deleteFile(params = {}){
		return request("get", "/media/delete_file", params)
	},

	// 移动图片到指定分组
	moveGroup(params = {}){
		return request("post", "/media/move_group", params)
	},
	
    // 添加分组
	addGroup(params = {}){
		return request("get", "/media/add_group", params)
	},

    // 删除分组
	deleteGroup(params = {}){
		return request("get", "/media/delete_group", params)
	},
}