import { render, staticRenderFns } from "./df-gallery-pictrue.vue?vue&type=template&id=f94940f6&scoped=true&"
import script from "./df-gallery-pictrue.vue?vue&type=script&lang=js&"
export * from "./df-gallery-pictrue.vue?vue&type=script&lang=js&"
import style0 from "./df-gallery-pictrue.vue?vue&type=style&index=0&id=f94940f6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f94940f6",
  null
  
)

export default component.exports