export default { 
	path: '/cashier',
	name: 'Cashier',
	redirect: '/cashier/audit',
	meta:{
		title: '前台',
		keepAlive: true
	},
	component: ()=> import('../views/cashier/index.vue'),
	children:[
		{
			path: 'order',
			name: 'Order',
			meta:{
				title: '开单收银',
				keepAlive: true
			},
			component: ()=> import('../views/cashier/order/order.vue'),
		},
		{
			path: 'audit',
			name: 'Audit',
			meta:{
				title: '水单审核',
				keepAlive: true
			},
			component: () => import('../views/cashier/audit/audit.vue')
		},
		{
		  path: 'reservation',
		  name: 'MarketingReservation',
		  meta: {
		    title: '预约管理'
		  },
		  component: () => import('../views/cashier/reservation/index.vue')
		},
		{
		  path: 'round',
		  name: 'Round',
		  meta: {
		    title: '员工轮牌'
		  },
		  component: () => import('../views/cashier/round/index.vue')
		},
		{
		  path: 'balance',
		  name: 'Balance',
		  meta: {
		    title: '收支管理'
		  },
		  component: () => import('../views/cashier/balance/balance.vue')
		}
	]
}