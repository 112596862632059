import {
	request
} from "../utils/axios";

export default {
	// 设置 ===> 消息模板
	getClassData(params = {}) {
		return request('get', `/template/get_class_data`, params);
	},

	getTemplateList(params = {}) {
		return request('get', `/template/get_template_list`, params);
	},

	getTemplateSelector(params = {}) {
		return request('get', `/template/get_template_selector`, params);
	},

    getTemplatePicker(params = {}) {
		return request('get', `/template/get_template_picker`, params);
	},

	getTemplateSymbols(params = {}) {
		return request('get', `/template/get_template_symbols`, params);
	},
    
    deleteTemplate(params = {}) {
		return request('get', `/template/delete_template`, params);
	},

    addTemplate(params = {}) {
		return request('post', `/template/add_template`, params);
	},

    importTemplates(params = {}) {
		return request('get', `/template/import_templates`, params);
	},
}