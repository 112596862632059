import { request } from "../utils/axios.js";

export default {
	// 紧急报警 ====> 报警管理 
	// 回复后端我已收到紧急报警
	ensureReceiveMessage(params){
		return request('get', `/aep/ensure_receive_message?msg_id=${params.msg_id}`);
	},

	initMessageList(params){
		return request('get', `/aep/init_message_list`, params);
	},

	getMessageList(params){
		return request('get', `/aep/get_message_list`, params);
	},

	acceptMessage(params){
		return request('get', `/aep/accept_message`, params);
	},

	getMessageHandleDetail(params){
		return request('get', `/aep/get_message_handle_detail`, params);
	},

	initHandleMessage(params){
		return request('get', `/aep/init_handle_message`, params);
	},

	submitHandleMessage(params){
		return request('post', `/aep/submit_handle_message`, params);
	},

	deleteMessageDetail(params){
		return request('get', `/aep/delete_message_detail`, params);
	},

	getMessageViewDetail(params){
		return request('get', `/aep/get_message_view_detail`, params);
	},

	getMessageSimpleList(params){
		return request('get', `/aep/get_message_simple_list`, params);
	},

	createDevice(params){
	    return request('post', `/aep/create_device`, params);
	}
}