import {
  request
} from "@/utils/axios.js"

export default {
  // 积分 ==> 积分兑换记录
  initExchangeRecordList(params = {}) {
    return request('GET', `/integral/init_exchange_record_list`, params)
  },

  getExchangeRecordList(params = {}) {
    return request('GET', `/integral/get_exchange_record_list`, params)
  },

  verifyExchangeRecord(params = {}) {
    return request('GET', `/integral/verify_exchange_record`, params)
  },

  reverseExchangeRecord(params = {}) {
    return request('GET', `/integral/reverse_exchange_record`, params, false, true, false, true)
  },

  manualApplyExportTarget(params = {}) {
    return request('GET', `/integral/manual_apply_export_target`, params)
  },

  manualReverseExportTarget(params = {}) {
    return request('GET', `/integral/manual_reverse_export_target`, params)
  },

  getGoodsByCard(params = {}) {
    return request('GET', `/integral/get_goods_by_card`, params)
  },

  addExchangeRecord(params = {}) {
    return request('POST', `/integral/add_exchange_record`, params)
  },

  getExchangeVerifyCode(params = {}) {
    return request('GET', `/integral/get_exchange_verify_code`, params)
  },

  // 积分 ==> 积分兑换物品
  initGoodsMaintList(params = {}) {
    return request('GET', `/integral/init_goods_maint_list`, params)
  },

  getGoodsMiantList(params = {}) {
    return request('GET', `/integral/get_goods_maint_list`, params)
  },

  initSaveGoods(params = {}) {
    return request('GET', `/integral/init_save_goods`, params)
  },

  saveGoods(params = {}) {
    return request('POST', `/integral/save_goods`, params)
  },

  initRuleEditList(params = {}) {
    return request('GET', `/integral/init_rule_edit_list`, params)
  },

  getRuleEditList(params = {}) {
    return request('GET', `/integral/get_rule_edit_list`, params)
  },

  saveRuleEditList(params = {}) {
    return request('POST', `/integral/save_rule_edit_list`, params)
  },

  restoreGoods(params = {}) {
    return request('GET', `/integral/restore_goods`, params)
  },

  disableGoods(params = {}) {
    return request('GET', `/integral/disable_goods`, params)
  },

  deleteGoods(params = {}) {
    return request('GET', `/integral/delete_goods`, params)
  },

  deleteRule(params = {}) {
    return request('GET', `/integral/delete_rule`, params)
  },

  createInteExpeGoods(params = {}) {
    return request('GET', `/integral/create_inte_expe_goods`, params)
  },

  createInteExportGoods(params = {}) {
    return request('GET', `/integral/create_inte_export_goods`, params)
  },
}