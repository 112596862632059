<template>
  <!-- start 项目选择对话框 -->
  <el-dialog
    title="选择商品"
    width="1000px"
    :visible.sync="showDialog"
    :append-to-body="appendToBody"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCloseDialog"
  >
    <!-- start 自定义内容 -->
    <div class="content-box">
      <!-- el-aside -->
      <el-aside>
        <el-tree
          :data="treeData"
          :props="{
            children: 'Children',
            label: 'Name',
          }"
          node-key="CategoryId"
          draggable
          accordion
          :highlight-current="true"
          :default-expanded-keys="defaultExpandedKeys"
          :allow-drag="allowDrag"
          :current-node-key="currentNodeKey"
          @node-click="nodeClick"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
          </span>
        </el-tree>
      </el-aside>
      <!-- end el-aside -->

      <!-- el-main -->
      <el-main>
        <div class="df-row">
          <div class="df-col">
            <el-select v-model="onsale" @change="onSearch">
              <el-option
                v-for="item in onSaleSelector"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="df-col" style="flex:1;">
            <el-input
              size="small"
              placeholder="输入商品名称或编号进行搜索"
              v-model="word"
              @input="onSearch"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </div>

        <!-- table -->
        <el-table
          ref="multipleTable"
          v-loading="loading"
          :data="tableData"
          row-key="ItemId"
          stripe
          :header-cell-class-name="headerStyle"
          @select="onSelectChange"
          @select-all="onSelectChange"
        >
          <el-table-column type="selection" width="46"></el-table-column>
          <el-table-column prop="ItemId" label="商品ID"></el-table-column>
          <el-table-column prop="ItemName" label="商品名称"></el-table-column>
          <el-table-column
            prop="CategoryName"
            label="商品分类"
          ></el-table-column>
          <el-table-column prop="" label="商品状态">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.OnSale ? 'success' : 'warning'"
                size="default"
                >{{ scope.row.OnSale ? "上架" : "下架" }}</el-tag
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- end table -->
      </el-main>
      <!-- end el-main -->
    </div>
    <!-- end 自定义内容 -->

    <!-- start 脚部 -->
    <span slot="footer" class="dialog-footer">
      <!-- pagination -->
      <el-pagination
        background
        :page-sizes="[15, 20, 25]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRecord"
        :pager-count="5"
        size="small"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <!--pagination-->

      <div>
        <el-button size="small" @click="onCloseDialog">取 消</el-button>
        <el-button size="small" type="primary" @click="onConfirmData"
          >保 存</el-button
        >
      </div>
    </span>
    <!-- end 脚部 -->
  </el-dialog>
  <!-- end 项目选择对话框 -->
</template>

<script>
import Shop from "@/api/shop.js";

export default {
  props: {
    // 是否参与拼团商品
    isGroupon: {
      type: Number,
      default: () => -1,
    },

    multiple: {
      // 表格是否多选
      type: Boolean,
      default: () => false,
    },

    appendToBody: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    headerStyle({ row, column, rowIndex, columnIndex }) {
      return this.multiple ? "" : "hideSelectAll";
    },
  },

  data() {
    return {
      pageIndex: 1, // 页码
      pageSize: 15, // 页数
      totalRecord: 0, // 总列数
      currentNodeKey: -1, // 选中项目组id
      onsale: -1,
      word: "", // 关键词
      treeData: [], // 树形项目组列表
      onSaleSelector: [], // 上架状态选择器
      tableData: [], // 消费项目表格数据
      selectItems: [], // 多选选中项目数据
      defaultExpandedKeys: [-1], // 树形控件默认勾选节点
      loading: false, // 表格加载
      showDialog: false, // 对话框显示
    };
  },

  methods: {
    // 初始化商品列表
    async initTreeData() {
      try {
        let { data } = await Shop.initItemMaintList();
        let treeData = [
          {
            CategoryId: -1,
            Prefix: "",
            Name: "全部",
            ParentId: 0,
            Image: "",
            Sort: 0,
            Level: 1,
            Leaf: 1,
            Children: [],
          },
        ];

        await data.categorySelector.forEach(async (item) => {
          if (item.ParentId == 0) {
            await treeData[0].Children.push(item);
          } else {
            for (let i = 0; i < treeData[0].Children.length; i++) {
              treeData[0].Children[i].Children == undefined
                ? (treeData[0].Children[i].Children = [])
                : "";
              if (treeData[0].Children[i].CategoryId == item.ParentId) {
                treeData[0].Children[i].Children.push(item);
              }
            }
          }
        });

        this.treeData = await treeData;
        this.onSaleSelector = await data.onSaleSelector;
      } catch (err) {
        console.log(err);
      }
    },

    // 查询商品列表
    async initPageData() {
      try {
        let { data } = await Shop.getItemMaintList({
          page_index: this.pageIndex,
          page_size: this.pageSize,
          word: this.word,
          category_id: this.currentNodeKey,
          onsale: this.onsale,
          is_groupon: this.isGroupon,
        });
        this.tableData = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      }
    },

    // 查询
    onSearch(){
      this.pageIndex = 1
      this.initPageData()
    },

    // 树形控件点击事件
    nodeClick(data, dragNode, enterNode) {
      this.currentNodeKey = data.CategoryId;
      this.defaultExpandedKeys = [data.CategoryId];
      this.initPageData();
    },

    // 判断节点能否被拖拽, 如果为 false 不允拖动。
    allowDrag(draggingNode) {
      return draggingNode.data.label.indexOf("全部") === -1;
    },

    // 多选框改变事件
    onSelectChange(event, row) {
      console.log(this.multiple, row);
      if (!this.multiple) {
        this.selectItems = [row];
        this.$refs.multipleTable.clearSelection();
        return this.onRowSelection();
      }
      this.selectItems = event;
    },

    // 多选回显
    onRowSelection() {
      this.$nextTick(() => {
        this.selectItems.forEach((item) => {
          for (let index = 0; index < this.tableData.length; index++) {
            if (this.tableData[index].ItemId === item.ItemId) {
              this.$refs.multipleTable.toggleRowSelection(
                this.tableData[index],
                true
              );
              break;
            }
          }
        });
      });
    },

    // 改变页面数据条数
    handleSizeChange(event) {
      this.pageIndex = 1;
      this.pageSize = event;
      this.initPageData();
    },

    // 改变页面码
    handleCurrentChange(event) {
      this.pageIndex = event;
      this.initPageData();
    },

    // 确定按钮点击事件
    onConfirmData() {
      this.$emit("onConfirm", { selectItems: this.selectItems });
      this.onCloseDialog();
    },

    // 打开对话框
    async onShowDialog(event) {
      this.selectItems = event ? JSON.parse(JSON.stringify(event)) : [];
      await this.initTreeData();
      await this.initPageData();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetSelectedData();
      this.showDialog = false;
    },

    // 重置数据
    resetSelectedData() {
      this.pageIndex = 1;
      // this.pageSize = 15
      this.totalRecord = 0;
      this.selectItems = [];
      this.currentNodeKey = -1;
      this.defaultExpandedKeys = [-1];
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  width: 100%;
  height: 40vh;
  .flex-row();

  .el-aside {
    width: 160px !important;
    height: 100%;
    overflow-y: auto;
  }

  .el-main {

    .el-table {
      margin-top: 20px;
      height: calc(40vh - 50px);
      overflow: auto;
      border: 0 !important;
    }
  }
}
.dialog-footer {
  .flex-row();
  justify-content: space-between;
  align-items: center;
}

::v-deep .hideSelectAll .el-checkbox {
  visibility: hidden;
}

.scrollbar();
</style>
