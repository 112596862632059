export default { 
	path: '/pressing',
	name: 'Pressing',
	hash: '/pressing',
	redirect: '/pressing/manage',
	meta:{
		title: '紧急报警',
		keepAlive: true
	},
	component: ()=> import('../views/pressing/index.vue'),
	children:[
		{
			path: 'main',
			name: 'Main',
			meta:{
				title: '紧急主页',
				keepAlive: true
			},
			component: ()=> import('../views/pressing/main/main.vue'),
		},
		{
			path: 'manage',
			name: 'Manage',
			meta:{
				title: '报警管理',
				keepAlive: true
			},
			component: ()=> import('../views/pressing/manage/manage.vue'),
		},
	]
}