<template>
  <div class="sync-item-dialog">
    <el-dialog
      :title="`同步到${storesName ? '总部' : '门店'}`"
      :visible.sync="showDialog"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <span v-if="storesName">目标门店：{{ storesName }}</span>
        <div v-else class="table-box">
          <el-table
            ref="multipleTable"
            :data="storesList"
            border
            stripe
            max-height="400px"
            @select="onSelectChange"
            @select-all="onSelectChange"
          >
            <el-table-column type="selection" width="46"></el-table-column>
            <el-table-column
              prop="StoresName"
              label="门店名称"
            ></el-table-column>
            <el-table-column label="同步进度">
              <template slot-scope="scope">
                <el-tag
                  v-if="storesList[scope.$index].State"
                  :type="storesList[scope.$index].State"
                  >{{ storesList[scope.$index].StateText }}</el-tag
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" :disabled="syncDisabled" @click="onSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <DfGetAuthKey
      ref="DfGetAuthKey"
      label="同步项目"
      :appendToBody="true"
      @success="onSync"
    ></DfGetAuthKey>
  </div>
</template>

<script>
export default {
  props: {
    syncFuction: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      //   targetStoresGuid: "",
      storesName: "",
      zbStoreGuid: "",
      storesList: [],
      //   copyStoresList: [],
      selectStores: [],
      storesGuids: [],
      selectItemGuids: [],
      showDialog: false,
      isSync: false,
      syncDisabled: false,
    };
  },

  mounted() {},

  methods: {
    // 同步
    async onSync(event = { auth_key: "", auth_code: "" }) {
      // console.log(event);
      let that = this;
      let { selectStores, storesName } = this,
        storesList = JSON.parse(JSON.stringify(this.storesList)),
        sucNum = 0,
        errNum = 0;

      if (!storesName && selectStores.length == 0) {
        return this.$message.error("请选择门店");
      }
      this.syncDisabled = true;

      if (storesName) {
        try {
          let result = await this.syncFuction({
            authKey: event.auth_key || "",
            authCode: event.auth_code || "",
            targetStoresGuid: this.zbStoreGuid,
          });
          if (result.errcode == 0) {
            that.$message.success("同步成功");
          } else {
            console.log(result);
            errNum++;
            this.syncDisabled = false;
          }
        } catch (err) {
          console.log(err);
          this.syncDisabled = false;
        }
      } else {
        for (let i = 0; i < selectStores.length; i++) {
          let index = storesList.findIndex(
            (e) => e.StoresGuid == selectStores[i].StoresGuid
          );

          try {
            storesList[index].State = "warning";
            storesList[index].StateText = "进行中";
            this.storesList = storesList;
            let result = await this.syncFuction({
              authKey: "",
              authCode: "",
              targetStoresGuid: selectStores[i].StoresGuid,
            });
            if (result.errcode == 0) {
              console.log(i, result);
              sucNum++;
              storesList[index].State = "success";
              storesList[index].StateText = "成功";
              // that.$message.success("同步成功");
              that.storesList = storesList;
            } else {
              console.log(result);
              errNum++;
              storesList[index].State = "danger";
              storesList[index].StateText = "失败";
              this.storesList = storesList;
              this.syncDisabled = false;
              return;
            }
            // console.log(res);
          } catch (err) {
            console.log(err);
            storesList[index].State = await "danger";
            storesList[index].StateText = await "失败";
            this.storesList = await storesList;

            this.syncDisabled = await false;
            return;
          }
          if (i == selectStores.length - 1) {
            this.$message.success(`同步成功`);
          }
        }
      }

      this.syncDisabled = false;
    },

    onSubmit() {
      let selectStoreGuid = window.sessionStorage.getItem("selectStoreGuid");

      if (this.zbStoreGuid != selectStoreGuid) {
        // console.log("ssss");
        return this.$refs.DfGetAuthKey.onShowDialog();
      }

      this.onSync();
    },

    // 多选框选中事件
    async onSelectChange(event, row) {
      let storesList = this.storesList;
      //   console.log(event);
      await storesList.forEach((item, index) => {
        storesList[index].State = "";
        storesList[index].StateText = "";
        event.forEach((item2) => {
          if (item2.StoresGuid == item.StoresGuid) {
            storesList[index].State = "info";
            storesList[index].StateText = "等待中";
          }
        });
      });
      //   this.storesList = await storesList;
      this.selectStores = event;
      this.storesGuids = event.map((item) => item.StoresGuid);
    },

    // 初始化数据
    initData() {
      let accountInfo = JSON.parse(
        window.sessionStorage.getItem("accountInfo")
      );
      let zbStoreIndex = accountInfo.manageStoresList.findIndex(
        (e) => e.HeadMark
      );
      let selectStoreGuid = window.sessionStorage.getItem("selectStoreGuid");
      this.authType = accountInfo.authType;
      this.zbStoreGuid = accountInfo.manageStoresList[zbStoreIndex].StoresGuid;

      if (this.zbStoreGuid == selectStoreGuid) {
        accountInfo.manageStoresList.splice(zbStoreIndex, 1);
        this.storesList = JSON.parse(
          JSON.stringify(accountInfo.manageStoresList)
        );
        // this.selectStores = JSON.parse(
        //   JSON.stringify(accountInfo.manageStoresList)
        // );
      } else {
        this.storesName = accountInfo.manageStoresList[zbStoreIndex].StoresName;
      }
    },

    resetData() {
      this.storesName = "";
      this.storesList = [];
      this.selectStores = [];
      this.storesGuids = [];
      this.selectItemGuids = [];
    },

    // 打开对话框
    onShowDialog(event) {
      this.initData();
      this.selectItemGuids = JSON.parse(JSON.stringify(event));
      // console.log(event);
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetData();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .table-box{
    // max-height: 400px;
    // overflow-y: auto;
  }
}
</style>
