export default {
  path: "/member",
  name: "Member",
  redirect: "/member/manage",
  meta: {
    title: "会员",
    keepAlive: true,
  },
  component: () => import("../views/member/index.vue"),
  children: [
    {
      /* 会员管理 */
      path: "manage",
      name: "Manage",
      redirect: "/member/manage/ship",
      meta: {
        title: "会员管理",
        keepAlive: true,
      },
      component: () => import("../views/member/manage/manage.vue"),
      children: [
        {
          path: "ship",
          name: "Ship",
          meta: {
            title: "会员资料",
            keepAlive: true,
          },
          component: () => import("../views/member/manage/ship/ship.vue"),
        },
        {
          path: "delete",
          name: "Delete",
          meta: {
            title: "会员删除",
            keepAlive: true,
          },
          component: () => import("../views/member/manage/delete/delete.vue"),
        },
        {
          path: "disable",
          name: "Disable",
          meta: {
            title: "停用会员",
            keepAlive: true,
          },
          component: () => import("../views/member/manage/disable/disable.vue"),
        },
      ],
    },
    {
      /* 分类设置 */
      path: "kind",
      name: "Kind",
      redirect: "/member/kind/category",
      meta: {
        title: "分类设置",
        keepAlive: true,
      },
      component: () => import("../views/member/kind/index.vue"),
      children: [
        {
          /* 会员分类 */
          path: "category",
          name: "Category",
          meta: {
            title: "会员分类",
            keepAlive: true,
          },
          component: () => import("../views/member/kind/category/list.vue"),
        },
        {
          /* 维度分类 */
          path: "dimension",
          name: "Dimension",
          meta: {
            title: "维度分类",
            keepAlive: true,
          },
          component: () => import("../views/member/kind/dimension/list.vue"),
        },
        {
          /* 会员卡分类设置 */
          path: "card-classify",
          name: "CardClassify",
          meta: {
            title: "会员卡分类设置",
            keepAlive: true,
          },
          component: () =>
            import("../views/member/kind/card-classify/card-classify.vue"),
        },
        {
          /* 标签管理 */
          path: "label",
          name: "Label",
          meta: {
            title: "标签管理",
            keepAlive: true,
          },
          component: () => import("../views/member/kind/label/list.vue"),
        },
        {
          path: "source",
          name: "Source",
          meta: {
            title: "顾客来源",
            keepAlive: true,
          },
          component: () => import("../views/member/kind/source/source.vue"),
        },
      ],
    },
    {
      path: "leave",
      name: "Leave",
      meta: {
        title: "流失预警",
        keepAlive: true,
      },
      component: () => import("../views/member/leave/list.vue"),
    },
    {
      /* 会员卡设置 */
      path: "card",
      name: "Card",
      redirect: "/member/card/list",
      meta: {
        title: "会员卡类型",
        keepAlive: true,
      },
      component: () => import("../views/member/card/card.vue"),
      children: [
        {
          path: "list",
          name: "cardNormal",
          meta: {
            title: "会员卡",
            keepAlive: true,
          },
          component: () => import("../views/member/card/list.vue"),
        },
        {
          path: "agreement",
          name: "Agreement",
          meta: {
            title: "会员办卡协议",
            keepAlive: true,
          },
          component: () => import("../views/member/card/agreement/agreement.vue"),
        },
      ],
    },
    {
      path: "select",
      name: "Select",
      meta: {
        title: "会员卡查询",
        keepAlive: true,
      },
      component: () => import("../views/member/select/select.vue"),
    },
    {
      path: "business",
      name: "Business",
      redirect: "/member/business/debt",
      meta: {
        title: "会员卡业务处理",
        keepAlive: true,
      },
      component: () => import("../views/member/business/index.vue"),
      children: [
        {
          path: "debt",
          name: "Debt",
          meta: {
            title: "会员欠款",
            keepAlive: true,
          },
          component: () => import("../views/member/business/debt/debt.vue"),
        },
        {
          path: "refund",
          name: "Refund",
          meta: {
            title: "会员退款",
            keepAlive: true,
          },
          component: () => import("../views/member/business/refund/refund.vue"),
        },
        {
          path: "trunMoney",
          name: "TrunMoney",
          meta: {
            title: "套卡次数转金额",
            keepAlive: true,
          },
          component: () =>
            import("../views/member/business/trun-money/trun-money.vue"),
        },
        {
          path: "reduce",
          name: "Reduce",
          meta: {
            title: "套卡次数删除",
            keepAlive: true,
          },
          component: () =>
            import("../views/member/business/reduce/reduce.vue"),
        },
      ],
    },
    {
      path: "analysis",
      name: "Analysis",
      meta: {
        title: "会员概况分析",
        keepAlive: true,
      },
      component: () => import("../views/member/analysis/analysis.vue"),
    },
    {
      path: "pack",
      name: "Pack",
      meta: {
        title: "套卡项目",
        keepAlive: true,
      },
      component: () => import("../views/member/pack/pack.vue"),
    },
    {
      path: "dona",
      name: "Dona",
      meta: {
        title: "赠送项目",
        keepAlive: true,
      },
      component: () => import("../views/member/dona/dona.vue"),
    },
  ],
};
