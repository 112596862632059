<template>
    <div v-loading="loading" class="df-get-auth-key">
      <el-dialog
        title="验证码"
        :visible.sync="showDialog"
        width="360px"
        :append-to-body="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @close="onCloseDialog"
      >
        <span class="content-box">
          <div class="df-row">
            <div class="df-col">
              <el-input v-model="authCode" placeholder="请输入验证码"></el-input>
            </div>
            <div class="df-col">
              <el-button type="primary" size="default" @click="getAuthKey"
                >获 取</el-button
              >
            </div>
          </div>
        </span>
  
        <span slot="footer">
          <el-button @click="onCloseDialog">取 消</el-button>
          <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
//   import maskStore from "@/store/userMaskStore.js";
  
  import Common from "@/api/common.js";
  
  export default {
    props: {
      label: {
        type: String,
        default: "",
      },
  
      // getKeyTitle: {
      //   type: String,
      //   default: "",
      // },
  
      isCode: {
        type: Boolean,
        default: true,
      },

      appendToBody: {
        type: Boolean,
        default: false,
      }
    },
  
    data() {
      return {
        // useMaskStore: maskStore(),
        authKey: "",
        authCode: "",
        showDialog: false,
        loading: false
      };
    },
  
    mounted() {
    },
  
    methods: {
      // 授权
      async getAuthKey() {
        // this.useMaskStore.tipsText = "是否要取消权限获取？";
        // this.useMaskStore.loading = true;
        this.loading = true
        try {
          let { data } = await Common.getAuthKey({
            // title: this.getKeyTitle || this.label,
            title: this.label,
          });
          this.authKey = data;
          // this.$emit("success", {
          //   auth_key: this.authKey,
          //   auth_code: this.authCode,
          // });
          // this.onCloseDialog();
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false
        //   this.useMaskStore.loading = false;
        }
      },
  
      // 确定按钮点击事件
      onSubmit() {
        this.$emit("success", {
          auth_key: this.authKey,
          auth_code: this.authCode,
        });
        this.onCloseDialog();
      },
  
      // 重置数据
      resetPageData() {
        this.authKey = "";
        this.authCode = "";
      },
  
      // 打开对话框
      onShowDialog(isClickBefore = true) {
        // if (isClickBefore) {
        //   this.$emit("clickBefore");
        // }
  
        this.showDialog = true;
      },
  
      // 关闭对话框
      onCloseDialog() {
        this.resetPageData();
        this.showDialog = false;
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .df-get-auth-key {
    ::v-deep .el-dialog__header{
      display: flex;
    }
    .el-dialog {
      .content-box {
        &:last-child .df-row {
          margin: 0;
        }
      }
    }
  }
  </style>
  