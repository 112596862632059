<template>
  <div class="df-gallery-pictrue">
    <el-dialog
      class="gallery-dialog"
      title="图片库"
      :visible.sync="showDialog"
      width="960px"
      append-to-body
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <el-container class="setting-dialog-box">
        <!-- 侧边卡类型项目栏菜单 -->
        <el-aside class="left" width="200px">
          <el-menu
            :default-active="submitData.group_guid"
            @select="onChangeAsideMenu"
          >
            <el-menu-item
              v-for="(item, index) in menuList"
              :key="index"
              :index="item.GroupGuid"
            >
              <div class="icon">
                <!-- <i v-if="index > 1" class="el-icon-edit"></i> -->
              </div>
              <span slot="title">{{ item.GroupName }}</span>
              <div class="icon clear">
                <i
                  v-if="index > 1"
                  class="el-icon-error"
                  @click.stop="onDeleteGroup(item)"
                ></i>
              </div>
            </el-menu-item>

            <span class="add-btn" @click="onShowGroupDialog">新增分组</span>
          </el-menu>
        </el-aside>

        <!-- 主布局 -->
        <el-container class="right">
          <el-main>
            <!-- 操作栏 -->
            <div class="df-row">
              <div class="df-col">
                <el-popover placement="bottom" width="140" trigger="click">
                  <div class="move-group">
                    <span class="title">请选择分组</span>
                    <span
                      v-for="(item, index) in choiceGroupList"
                      :key="index"
                      @click="onMoveGroup(item)"
                      >{{ item.GroupName }}</span
                    >
                  </div>

                  <el-button slot="reference" size="small" type="primary">
                    移动至
                    <i class="el-icon-caret-bottom el-icon--right"></i>
                  </el-button>
                </el-popover>
              </div>

              <div class="df-col">
                <el-button
                  size="small"
                  type="danger"
                  :disabled="deleteDisabled"
                  @click="onDeletePictrue"
                  >删除</el-button
                >
              </div>

              <div class="df-col">
                <el-upload
                  class="upload-demo"
                  :multiple="false"
                  :show-file-list="false"
                  :drag="false"
                  :action="uploadSwiperUrl + `?group_guid=${submitData.group_guid}`"
                  :headers="headers"
                  :on-success="onUploadSuccessSwiper"
                  :on-error="onUploadError"
                  :before-upload="beforeAvatarUpload"
                  :on-exceed="onUploadExceed"
                >
                  <el-button size="small" type="primary" plain
                    >+ 上传图片</el-button
                  >
                </el-upload>
              </div>
            </div>

            <!-- 图片列表 -->
            <div class="media-list">
              <div
                class="media-item"
                v-for="(item, index) in mediaList"
                :key="index"
                @click="onSelectMedia(index)"
              >
                <el-image :src="item.MediaUrl" fit="contain"></el-image>
                <span>{{ item.MediaName }}</span>

                <i v-if="item.selected" class="selected el-icon-check"></i>
              </div>
            </div>
          </el-main>

          <el-footer>
            <!-- start 分页器 -->
            <el-pagination
              background
              :page-sizes="[10, 20, 30]"
              :page-size="submitData.page_size"
              :current-page="submitData.page_index"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalRecord"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>

            <!-- 按钮组 -->
            <div class="btn-box">
              <!-- <el-button size="mini" @click="onCloseDialog">
                取 消
              </el-button> -->
              <el-button
                size="mini"
                type="primary"
                :disabled="submitDisabled"
                @click="onSubmitData"
              >
                确 定
              </el-button>
            </div>
          </el-footer>
        </el-container>
      </el-container>
    </el-dialog>

    <!-- 添加对话款 -->
    <el-dialog
      title="请输入新分组名称"
      :visible.sync="groupDialog"
      top="15%"
      width="15%"
      append-to-body
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseGroupDialog"
    >
      <span>
        <el-input v-model="groupName" size="mini" clearable></el-input>
      </span>

      <span slot="footer">
        <el-button size="mini" @click="onCloseGroupDialog">
          取 消
        </el-button>
        <el-button
          size="mini"
          type="primary"
          :disabled="submitDisabled"
          @click="onAddGroup"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Media from "@/api/media.js";

import { baseURL } from "@/utils/axios.js";

export default {
  data() {
    return {
      // 上传组件参数设置
      uploadSwiperUrl: baseURL + `/media/upload_file`, // 上传图片接口地址
      headers: {
        // 头部
        acctoken: window.sessionStorage.getItem("token"),
      },

      // 对话框数据
      groupName: "",  // 分组名称
      totalRecord: 0, // 列表总数
      submitData: {
        // 提交数据
        group_guid: "",
        page_index: 1,
        page_size: 8,
      },
      menuList: [], // 分组列表
      choiceGroupList: [], // 可选的分组列表
      mediaList: [], // 图片列表
      showDialog: false, // 对话框显示
      groupDialog: false, // 新增分组对话框显示
      submitDisabled: false, // 提交防抖
      deleteDisabled: false, // 删除防抖
      moveDisabled: false,  // 移动至防抖
    };
  },

  methods: {
    // 获取分组列表
    async getGroupList() {
      try {
        let { data } = await Media.getGroupList();
        this.menuList = data;
        this.choiceGroupList = data.slice(1);
      } catch (error) {
        console.log(error);
      }
    },

    // 获取图片列表
    async getMediaList() {
      try {
        let { data } = await Media.getMediaList(this.submitData);
        data.list.forEach((item) => (item.selected = false));
        this.mediaList = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (error) {
        console.log(error);
      }
    },

    // 删除图片
    async onDeletePictrue() {
      this.deleteDisabled = true;

      let mediaGuid = [];
      this.mediaList.forEach((item) => {
        // console.log(item);
        if (item.selected) {
          mediaGuid.push(item);
        }
      });

      try {
        let { errcode } = await Media.deleteFile({
          media_guid: mediaGuid[0].MediaGuid,
        });

        if (errcode == 0) {
          this.$message.success("删除成功!");
          return this.onCloseDialog();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.deleteDisabled = false;
      }
    },

    // 添加分组
    async onAddGroup() {
      if (!this.groupName) {
        return this.$message.error("请输入分组名称");
      }

      this.submitDisabled = true;
      try {
        let { errcode } = await Media.addGroup({
          group_name: this.groupName,
        });
        if (errcode == 0) {
          this.$message.success("添加分组成功!");
          this.onCloseGroupDialog();
          this.getGroupList();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.submitDisabled = false;
      }
    },

    // 删除分组
    async onDeleteGroup(event) {
      if (this.deleteDisabled) {
        return;
      }
      this.deleteDisabled = true;

      this.$confirm(`删除分组后将无法恢复，确定要删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { errcode } = await Media.deleteGroup({
              group_guid: event.GroupGuid,
            });
            if (errcode == 0) {
              this.$message.success("删除成功!");
              this.getGroupList();
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.deleteDisabled = false;
          }
        })
        .catch((err) => {
          this.deleteDisabled = false;
        });
    },

    // 上传轮播图成功
    async onUploadSuccessSwiper(event) {
      this.$message.success("图片上传成功~");
      await this.resetData();
      await this.getMediaList();
    },

    // 将移动至其他分组
    async onMoveGroup(event){
      if(this.moveDisabled){
        return
      }
      let {mediaList} = this
      let mediaGuids = []

      for(let i = 0; i < mediaList.length; i++){
        await mediaList[i].selected ? await mediaGuids.push(mediaList[i].MediaGuid) : await null
      }
      if(await !mediaGuids.length){
        return this.$message.error('请选择至少一张图片')
      }

      this.moveDisabled = true

      this.$confirm(`确定选中图片移动至【${event.GroupName}】吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async ()=>{
        try {
          let { errcode } = await Media.moveGroup({
            MediaGuids: mediaGuids,
            NewGroupGuid: event.GroupGuid
          })
          if(errcode == 0){
            this.$message.success('移动成功!')
            this.getMediaList()
          }
        } catch (error) {
          console.log(error);
        } finally{
          this.moveDisabled = false
        }
      }).catch(()=>{
        this.moveDisabled = false
      })
    },

    // 上传失败 (共有的)
    onUploadError() {
      this.$message.error("上传图片失败!");
    },

    // 上传文件限制事件 (共有的)
    beforeAvatarUpload(file) {
      // console.log(file);
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";
      const isBMP = file.type === "image/bmp";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isGIF && !isPNG && !isBMP) {
        this.$message.error("上传图片必须是JPG/GIF/PNG/BMP 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return (isJPG || isBMP || isGIF || isPNG) && isLt2M;
    },

    // 上传文件超出数量 (共有的)
    onUploadExceed(event) {
      this.$message.error("上传文件超出数量!");
    },

    // 确认提交图片
    onSubmitData() {
      let arr = this.mediaList.filter((item) => item.selected);
      this.onCloseDialog();
      this.$emit("onComfirm", arr);
    },

    // 分组改变事件
    onChangeAsideMenu(event) {
      this.submitData.group_guid = event;
      this.getMediaList();
    },

    // 选中图片
    onSelectMedia(index) {
      let boolean = this.mediaList[index].selected ? true : false;
      this.mediaList[index].selected = !boolean;
      // console.log(this.mediaList);
    },

    // 重置数据
    resetData() {
      this.submitData = {
        group_guid: this.submitData.group_guid,
        page_index: 1,
        page_size: 10,
      };
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getMediaList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getMediaList();
    },

    // 打开添加分组对话框
    onShowGroupDialog() {
      this.groupDialog = true;
    },

    // 关闭添加分组对话框
    onCloseGroupDialog() {
      this.groupName = "";
      this.groupDialog = false;
    },

    // 打开对话框
    onShowDialog() {
      this.showDialog = true;
      this.getGroupList();
      this.getMediaList();
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
 .gallery-dialog .el-dialog {
  height: 580px !important;

  .el-dialog__body {
    padding: 0 !important;
  }

  .setting-dialog-box {
    height: 520px;
    // border: 1px solid black;

    .left {
      width: 150px !important;
      height: 100%;
      // padding: 0 20px;
      border-right: 1px solid #e6e6e6;

      .el-menu {
        width: 100%;
        height: 100%;
        border: 0;
        text-align: center;
        // border: 1px solid black;

        .el-menu-item {
          .flex-row();
          // text-align: left;
          padding: 0 !important;

          .icon {
            width: 22px;
            height: 22px;
            line-height: unset;

            i {
              display: none;
              margin: 0;
            }
          }

          .title {
            width: calc(100% - 44px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .clear {
            position: absolute;
            right: 5px;
          }

          &:hover {
            span,
            i {
              color: #409eff;
            }
            .icon i {
              display: block;
            }
          }
        }
        .add-btn {
          display: block;
          width: 100%;
          padding: 20px 22px;
          text-align: left;
          color: #409eff;
          box-sizing: border-box;
          cursor: pointer;

          &:hover {
            color: #2275c9;
          }
        }
      }
    }

    .right {
      .el-main {
        position: relative;
        margin: 0;
        padding: 0;

        .df-row {
          padding: 0 20px;
        }

        .media-list {
          .flex-row();
          flex-wrap: wrap;
          max-height: calc(100% - 70px);
          padding: 0 10px;
          overflow-y: auto;

          .media-item {
            position: relative;
            .flex-col();
            width: 140px;
            height: 160px;
            margin: 10px 10px;
            box-sizing: border-box;
            border-radius: 3px;
            box-shadow: 0 0px 4px rgba(0, 0, 0, 0.12),
              0 0 6px rgba(0, 0, 0, 0.04);
            transition: all 0.3s ease-in-out;
            overflow: hidden;

            .el-image {
              width: 100%;
              height: 100%;
            }

            span {
              width: 140px;
              padding: 5px 10px;
              box-sizing: border-box;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .media-item:hover {
            box-shadow: 0 0px 4px rgba(0, 35, 148, 0.5),
              0 0 6px rgba(57, 93, 211, 0.2);
            cursor: pointer;
          }
          .selected {
            position: absolute;
            top: 0;
            left: 0;
            .flex-row();
            justify-content: center;
            width: 140px;
            height: 160px;
            font-size: 60px;
            font-weight: bold;
            color: white;
            background-color: rgba(0, 0, 0, 0.5);
          }
        }

        // .el-pagination {
        //   position: absolute;
        //   bottom: 0;
        //   right: 0;
        //   float: right;
        //   margin-top: 20px;
        // }
      }
    }

    .el-footer {
      height: 30px !important;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }
  }
}

.el-popover {
  .move-group {
    .flex-col();
    height: 100%;
    max-height: 300px;
    margin: 5px 0;
    box-sizing: border-box;
    overflow-y: auto;

    span {
      display: block;
      width: 100%;
      padding: 8px 0;
      text-align: center;
      font-size: 14px;
      color: black;

      &:hover{
        background-color: rgba(0, 0, 0, .1);
        cursor: pointer;
      }
    }

    .title{
      font-size: 12px;
      color: #888;
      &:hover{
        background-color: unset;
        cursor: unset;
      }
    }
  }
}

.scrollbar();
</style>
