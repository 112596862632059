import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { MessageBox, Message } from "element-ui";
import Common from "@/api/common.js";

// 判断是否是今日 -1 过去 0 当日  1 未来
export const isTodayFun = (timestamp) => {
  let date = new Date();
  let dateTarget = new Date(timestamp);

  let yNow = date.getFullYear();
  let mNow = date.getMonth() + 1;
  let dNow = date.getDate();

  let yTarget = dateTarget.getFullYear();
  let mTarget = dateTarget.getMonth() + 1;
  let dTarget = dateTarget.getDate();

  if (yNow === yTarget && mNow === mTarget && dNow === dTarget) {
    return 0;
  }
  // 如果现在年份大于目标年份
  if (yNow > yTarget) {
    return -1;
  }
  // 如果现在年份小于目标年份
  if (yNow < yTarget) {
    return 1;
  }

  // 如果年份相等时 现在月份大于目标月份
  if (mNow > mTarget) {
    return -1;
  }
  // 如果年份相等时 现在月份小于于目标月份
  if (mNow < mTarget) {
    return 1;
  }

  // 如果年份、月份相等  现在日期大于目标日期
  if (dNow > dTarget) {
    return -1;
  }
  // 如果年份、月份相等  现在日期大于目标日期
  if (dNow < dTarget) {
    return 1;
  }
};

export const addZero = (value) => {
  if (Number(value) < 10) {
    return `0${value}`;
  } else {
    return value;
  }
};
// 计算结束时间 beginTime => 10:00 time number 单位分钟 默认15
export const getEndTime = (beginTime, time = 15) => {
  let beginTimestamp = new Date(`2020-01-01 ${beginTime}`).getTime();
  let endTimestamp = beginTimestamp + time * 1000 * 60;
  let endDate = new Date(endTimestamp);

  if (new Date(`2020-01-01 ${beginTime}`).getDate() != endDate.getDate()) {
    return false;
  }
  return `${addZero(endDate.getHours())}:${addZero(endDate.getMinutes())}`;
};

// 计算结束时间 是否超过营业时间和休息时间 beginTime预约时间 endTime结束时间 => 00:00 timeRange 营业时间段
export const judgeEndTime = (beginTime, endTime, timeRange) => {
  if (!beginTime || !endTime || timeRange.length == 0) {
    return "参数错误";
  }
  let beginTimestamp = formatHM(beginTimestamp);
  let endTimestamp = formatHM(endTime);
  let index = -1;
  for (let i = timeRange.length - 1; i >= 0; i--) {
    if (timeRange[i].Begin == beginTime) {
      index = i;
      break;
    }
  }

  let status = true;
  // 判断结束时间 会不会遇到休息时间/关门时间
  for (let i = index; i < timeRange.length; i++) {
    let x = formatHM(timeRange[i].Begin);
    let y = formatHM(timeRange[i].End);
    // 如果在本时间段内
    if (endTimestamp >= x && endTimestamp <= y) {
      status = true;
      break;
    }
    // 非最后一个时间段
    if (i != timeRange.length - 1) {
      let x2 = formatHM(timeRange[i + 1].Begin);
      let y2 = formatHM(timeRange[i + 1].End);
      if (endTimestamp < x2) {
        status = false;
        break;
      }
    }
    // 最后一个时间段
    if (i == timeRange.length - 1) {
      if (endTimestamp > y) {
        status = false;
      } else {
        status = true;
      }
      break;
    }
  }
  return status;
};

// 把时间分钟转换成时间戳 => 用于比较当日的两个时间
function formatHM(value) {
  if (!value) {
    return "参数错误";
  }
  return new Date(`2020-01-01 ${value}`).getTime();
}

// 获取url参数
export const getQueryVariable = (variable) => {
  if (!variable) {
    return "缺少查询参数";
  }
  // VUE hash 写法
  let query = window.location.hash.substring(1);
  let vars = query.split("?")[1].split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};

// 验证预约时间 格式: '10:00-12:00,14:00-20:00'
export const formatTimeQuantum = (time) => {
  const reg = /^\d{2}:\d{2}-\d{2}:\d{2}(,\d{2}:\d{2}-\d{2}:\d{2})*$/;
  return reg.test(time);
};

export default {
  getOwnPowers() {
    return JSON.parse(window.sessionStorage.getItem("accountInfo")).powers;
  },

  checkPower(power, parameter, callback) {
    /* 判断用户是否有该权限 , 0 没有权限 1 临时权限 2 有权限 */
    let hasPower = this.getOwnPowers().findIndex((value) => {
      return value == power;
    });
    if (hasPower >= 0) {
      callback({
        msg: "",
      });
      return;
    }
    /* 如果用户没有该权限，就去查找该权限是否临时授权 */
    let powerList = JSON.parse(window.sessionStorage.getItem("systemData"))
      .powerList;
    // console.log(powerList);
    let foundPowerIndex = powerList.findIndex((value) => {
      return value.PowerNo == power;
    });

    if (powerList[foundPowerIndex].AccreditMark == 1) {
      parameter.show = true;
      parameter.power = power;
      parameter.key = "";
      parameter.code = "";
      parameter.callback = callback;
      return;
    } else {
      Message.error(`您没有${powerList[foundPowerIndex].PowerName}权限`);
      return;
    }
  },

  // YYYY-mm-dd HH:MM:SS
  dateFormat(fmt, date) {
    const opt = {
      "Y+": date.getFullYear().toString(), // 年
      "m+": (date.getMonth() + 1).toString(), // 月
      "d+": date.getDate().toString(), // 日
      "H+": date.getHours().toString(), // 时
      "M+": date.getMinutes().toString(), // 分
      "S+": date.getSeconds().toString(), // 秒
      "F+": date.getMilliseconds().toString(),
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    let ret;
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(
          ret[1],
          ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
        );
      }
    }
    return fmt;
  },

  // 时间戳转换成yyyy MM dd hh mm dd
  formatDate(value, type = 0) {
    if (typeof value == "undefined") {
      return "";
    } else {
      if (value.toString().length == 10) {
        value = value * 1000;
      }
      let date = new Date(parseInt(value));
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      if (type == 0) {
        return y + "-" + MM + "-" + d + " " + h + ":" + m;
      } else if (type == 1) {
        return h + ":" + m;
      } else if (type == 2) {
        return y + "-" + MM + "-" + d;
      }
    }
  },

  /**
   * 获取本月第一天
   **/
  getNowMonthFirst() {
    const date = new Date();
    date.setDate(1);
    return this.dateFormat("YYYY-mm-dd", date);
  },

  /**
   * 获取本月最后一天
   **/
  getNowMonthLast() {
    const date = new Date();
    const enddate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return this.dateFormat("YYYY-mm-dd", enddate);
  },

  /**
   * 获取本月当天
   **/
  getNowMonthToday() {
    return this.dateFormat("YYYY-mm-dd", new Date());
  },

  /**
   * 获取本月
   **/
  getMonth() {
    return this.dateFormat("YYYY-mm", new Date());
  },

  /* 时间添加
   * @param {date} date 时间对象
   * @param {string} part 需要增加的单位
   * @param {number} num 需要增加的数量
   */
  dateAdd(date, part, num) {
    let result = date;
    if (part == "y") {
      var m = date.getMonth();
      result.setFullYear(date.getFullYear() + num);
      if (m < result.getMonth()) {
        result.setData(0);
      }
    } else if (part == "m") {
      var d = date.getDate();
      result.setMonth(date.getMonth() + m);
      if (d > result.getDate()) {
        result.setDate(0);
      }
    } else if (part == "d") {
      result.setDate(date.getDate() + num);
    } else if (part == "h") {
      result.setHours(date.getHours() + num);
    }
    return result;
  },

  calculateTimeDifference(timestamp1, timestamp2) {
    // 确保参数是数字
    timestamp1 = parseInt(timestamp1, 10);
    timestamp2 = parseInt(timestamp2, 10);

    // 计算时间差（毫秒）
    let timeDifference = Math.abs(timestamp2 - timestamp1);

    let hours = Math.floor(timeDifference / (1000 * 60 * 60));
    timeDifference %= 1000 * 60 * 60;
    let minutes = Math.floor(timeDifference / (1000 * 60));
    timeDifference %= 1000 * 60;
    let seconds = Math.floor(timeDifference / 1000);

    // 格式化时间差为HH:MM:SS
    let result =
      String(hours).padStart(2, "0") +
      ":" +
      String(minutes).padStart(2, "0") +
      ":" +
      String(seconds).padStart(2, "0");

    return result;
  },

  // 需要渲染的Value和Name转换格式化
  valueAndNameChange(item, sourceList, keyName = "Value", valueName = "Name") {
    let res = sourceList.filter((event) => event[keyName] == item);
    return res == "" ? "无" : res[0][valueName];
  },

  // 分组格式化
  uniteIndexChange(uniteIndex) {
    let str = "";
    switch (uniteIndex) {
      case 1:
        str = "一";
        break;
      case 2:
        str = "二";
        break;
      case 3:
        str = "三";
        break;
      case 4:
        str = "四";
        break;
      case 5:
        str = "五";
        break;
    }
    return str == "" ? "无" : str + "组";
  },

  // 深拷贝
  jsonCopy(event) {
    return JSON.parse(JSON.stringify(event));
  },

  // 导出表格
  onExportExcel(domName, fileName, dom = "") {
    let domData = dom || document.querySelector(domName);

    let xlsxParam = {
      raw: true,
    };
    let wb = XLSX.utils.table_to_book(domData, xlsxParam);
    let wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "array",
    });
    // console.log(domData);
    try {
      FileSaver.saveAs(
        new Blob([wbout], {
          type: "application/octet-stream",
        }),
        `${fileName}.xlsx`
      );

      return wbout;
    } catch (err) {
      typeof console !== "undefined" ? console.log(err, wbout) : null;
    }
  },

  /** 获取浏览器窗口信息
   *  @returns {object}
   *  - @param {number} clientHeight 浏览器窗口高度
   */
  getDocumentElementInfo() {
    let { clientHeight, clientWidth } = document.documentElement;
    let { innerHeight, innerWidth } = window;
    return {
      clientHeight,
      clientWidth,
      innerWidth,
    };
  },

  // 判断是否是移动端
  isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // console.log(userAgent);
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    );
  },

  // 获取表格行class样式
  tableRowClassName(event) {
    let { row, rowIndex } = event;
    if (rowIndex % 2 == 0) {
      return "table-gray-row";
    }
    return "";
  },

  // 获取表格列class样式
  tableCellClassName(event) {
    let { row, column, rowIndex, columnIndex } = event;
    let { property } = column;
    let index = property ? property.indexOf("Roya") : -1;
    if (index > -1 && property != "TotalRoya") {
      return "table-red-font";
    }
    return "";
  },

  // 处理职位级别
  handleDutyLevel(dataInfo, copyLevelList) {
    let arr = copyLevelList.filter(
      (item) => item.DutyNo == dataInfo.DutyNo && item.DutyNo != ""
    );
    let levelList = [copyLevelList[0], ...arr];
    dataInfo.LevelGuid = levelList[0].LevelGuid;
    return { levelList, dataInfo };
  },

  getResize(dom, height = 0) {
    let { clientHeight } = this.getDocumentElementInfo();
    dom.tableMaxHeight = clientHeight - height;
  },

  elapseBegin(content) {
    let basic = "";
    basic += `\r\n${content}`;
    window.sessionStorage.setItem("elapse", basic);
  },

  elapseNext(content) {
    let basic = window.sessionStorage.getItem("elapse");
    basic += `\r\n${content}`;
    window.sessionStorage.setItem("elapse", basic);
  },

  async elapseEnd(content) {
    let basic = window.sessionStorage.getItem("elapse");
    basic += `\r\n${content}`;
    try {
      let { errcode } = Common.postFrontLog({
        logText: basic,
      });
      MessageBox.confirm(basic, "提示", {
        confirmButtonText: "确定",
      });
    } catch (err) {
      console.log(err);
    }
  },
};
