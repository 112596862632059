<template>
  <!-- 短信模板对话框 -->
  <el-dialog
    :visible.sync="showDialog"
    width="30%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCloseDialog"
  >
    <!-- 标题区 -->
    <div slot="title" class="dialog-top">
      <span class="title">选择{{applyType == 1 ? '微信' : '短信'}}模板</span>

      <!-- <div class="promotion-diagram">
        <i class="el-icon-picture-outline blue"></i>
        <span class="blue">点击生成推广图</span>
        <el-popover placement="right" trigger="hover">
          <div class="content">
            此推广图用于增加品牌曝光度，顾客可在短信链接中打开
            ，引导顾客扫码关注微信公众号
          </div>
          <i class="el-icon-warning-outline" slot="reference"></i>
        </el-popover>
      </div> -->
    </div>

    <!-- 内容区 -->
    <div class="sms-template-dialog">
      <el-radio-group v-model="activeRadio">
        <el-radio
          v-for="(item, index) in templateList"
          :key="index"
          :label="index"
          border
          >{{ item.TplContent }}</el-radio
        >
      </el-radio-group>
    </div>

    <!-- 操作区 -->
    <div slot="footer" class="dialog-bottom">
      <div class="tips">
        <span>没有想要的短信模板？</span>
        <span @click="goto">自定义短信模板></span>
      </div>

      <div>
        <el-button size="small" @click="onCloseDialog">取消</el-button>
        <el-button type="primary" size="small" @click="onConfirmSave"
          >确定</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Template from "@/api/template.js";

export default {
  data() {
    return {
      activeRadio: -1, // 选中模板下标
      applyType: 0, // 消息模板类型
      classCode: "", // 模板分类
      templateList: [], // 短信模板列表
      showDialog: false, // 对话框显示
    };
  },

  methods: {
    // 获取模板列表
    async getTemplatePicker() {
      try {
        let { data } = await Template.getTemplatePicker({
          class_code: this.classCode,
          apply_type: this.applyType,
        });
        this.templateList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 确定按钮点击事件
    onConfirmSave() {
      if (this.activeRadio > -1) {
        this.$emit("confirm", this.templateList[this.activeRadio]);
      }
      this.onCloseDialog();
    },

    // 打开对话框
    onShowDialog({ applyType = 0, classCode = "" }) {
      this.applyType = applyType;
      this.classCode = classCode;
      this.getTemplatePicker();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.activeRadio = -1;
      this.showDialog = false;
    },

    // 跳转到消息模版
    goto() {
      this.$router.push(`/setting/basic/template?applyType=${this.classCode}`);
    },
  },
};
</script>

<style lang="less" scoped>
.sms-template-dialog {
  height: 400px;
  overflow-y: auto;

  .el-radio-group {
    .flex-col();
    align-items: unset;

    ::v-deep .el-radio {
      .flex-row();
      align-items: center;
      height: auto;
      margin: 10px 0 !important;
      padding: 8px 10px;
      box-sizing: border-box;

      > span {
        position: unset;
        display: block;
        font-size: 12px;
        white-space: normal !important;
      }

      .el-radio__label {
        line-height: 18px;
      }
    }
  }
}

.dialog-top {
  .flex-row();
  justify-content: unset;
  align-items: unset;

  .title {
    font-size: 20px;
  }

  .promotion-diagram {
    .flex-row();
    align-items: flex-end;
    margin-left: 20px;
    .blue {
      color: #409eff !important;
    }

    span {
      padding: 0 3px;
      font-size: 12px;
      cursor: pointer;
    }

    .market-i(0);
    i:first-child {
      cursor: unset !important;
    }
  }
}

.dialog-bottom {
  .flex-row();
  justify-content: space-between;
  .tips {
    font-size: 12px;
    color: #999;

    :last-child {
      color: #409eff;
      cursor: pointer;
    }
  }
}

.el-popover {
  .content {
    padding: 4px 8px !important;
    font-size: 12px;
  }
}
</style>
