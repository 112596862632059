<template>
  <el-popover placement="top" v-model="showPopover" trigger="click">
    <div class="memeber-content" style="padding: 20px;">
      <ul style="height: 300px; width: 720px; overflow-y: scroll;">
        <li
          @click="onSelectMember(item)"
          v-for="(item, index) in initComponentData"
          :key="item.MemberGuid"
          style="padding-bottom: 10px; display: flex; cursor: pointer;"
        >
          <span style="flex: 1;">{{ item.MemberName }}</span>
          <span style="flex: 1;">{{ item.MemberPhone }}</span>
          <span style="flex: 2;">{{ item.StoresName }}</span>
        </li>
      </ul>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="submitData.page_index"
        :page-size="submitData.page_size"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div slot="reference" class="reference">
      <el-input
        :placeholder="placeholder"
        :value="message"
        :size="size"
        clearable
        @focus="focusInput"
        @clear="onClearInput"
        @input="timerQuery($event)"
      >
        <el-button slot="append" icon="el-icon-search" @click="onSearchMember">
        </el-button>
      </el-input>
    </div>
  </el-popover>
</template>

<script>
import api from "../../api/member.js";

export default {
  model: {
    prop: "message", // v-model接收的值=props的message属性接收的值
    event: "input", //v-model发生变化时触发的方法,随意写成abc都行重要的是要对应$emit的方法名,例如：这里event填'input'则 $emit('input(对应你event填的方法名)', value)
  },

  props: {
    message: {
      type: String || Number,
      default: "",
    },

    inputText: {
      type: String,
      default: "",
    },

    size: {
      type: String,
      default: "small",
    },

    placeholder: {
      type: String,
      default: "请输入内容",
    },
  },

  data() {
    return {
      showPopover: false,
      submitData: {
        word: "",
        page_index: 1,
        page_size: 10,
      },
      interval: {},
      total: 0,
      initComponentData: [],
    };
  },

  methods: {
    // 定时查询
    timerQuery(event) {
      this.$emit("input", event); // 对应v-model的event方法名，并且一定要$emit出去外部，不然外部无法接收值
      this.submitData.word = event;
      clearInterval(this.interval);
      this.interval = setInterval(this.onSearchMember, 800);
    },

    async onSearchMember() {
      try {
        let { data } = await api.querySelector(this.submitData);
        this.initComponentData = data.list;
        this.total = data.filter.TotalRecord;
        clearInterval(this.interval);
        this.$emit("success");
        // console.log(data);
      } catch (err) {}
      this.showPopover = true;
    },

    // onBeforeSearch() {
    //   let next = false;
    //   this.$emit("beforeSearch", next);
    //   if (next) {
    //     this.onSearchMember();
    //   }
    // },

    focusInput() {},

    onClearInput() {
      this.$emit("change", "");
    },

    onSelectMember(event) {
      this.showPopover = false;
      this.submitData.word = event.MemberName;
      this.$emit("change", JSON.parse(JSON.stringify(event)));
    },

    handleSizeChange(event) {
      this.submitData.page_size = event;
      this.submitData.page_index = 1;
      this.onSearchMember();
    },

    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.onSearchMember();
    },
  },
};
</script>

<style lang="less">
.reference {
  display: flex;

  .memeber-content {
    padding: 20px;
  }
}
</style>
