<template>
  <div>
    <el-button v-if="checkAllow ? AllowExportData : true" type="primary" size="mini" @click="onExport">
      导出表格
    </el-button>

    <el-dialog
      title="提示"
      :visible.sync="showDialog"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      @close="onCloseDialog"
    >
      <span>
        <div v-if="fileName === ''" class="tips">
          <i class="el-icon-loading" style="font-size: 28px"></i
          >正在导出中，请稍等...
        </div>
        <div v-else>
          表格导出成功！！请点击<a
            :href="baseUrl + domainUrl + fileName"
            target="_blank"
            @click="onCloseDialog"
            >[下载]</a
          >
        </div>
      </span>
      <span v-if="fileName" slot="footer">
        <el-button @click="onCloseDialog">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { baseURL } from "@/utils/axios.js";

export default {
  props: {
    domainUrl: {
        type: String,
        default: "/common/download_temp_file?file_name="
    },

    fileName: {
        type: String,
        default: ""
    },

    checkAllow:{
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      baseUrl: baseURL,
      AllowExportData: 1,
      showDialog: false,
    };
  },

  created() {
    let systemData = JSON.parse(window.sessionStorage.getItem("systemData"))
    this.AllowExportData = systemData.setting.AllowExportData
  },

  methods: {
    onExport(){
        this.$emit('export');
        this.onShowDialog()
    },

    onShowDialog() {
      this.showDialog = true;
    },

    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  .flex-row;
  i {
    margin-right: 5px;
  }
}

a {
  margin: 0 5px;
}
</style>
