<template>
  <div>
    <!-- 编辑备注抽屉 -->
    <el-drawer
      :title="title"
      :visible.sync="showDrawer"
      :wrapperClosable="false"
      direction="rtl"
      size="35%"
      @close="onCloseDrawer"
    >
      <!-- 文本编辑器 -->
      <quillEditor
        id="editor"
        ref="myQuillEditor"
        :options="options"
        v-model="note"
        @ready="onEditorReady($event)"
      ></quillEditor>
    </el-drawer>

    <!-- 图库对话框 -->
    <DFGalleryPictrue
      ref="DFGalleryPictrue"
      @onComfirm="onSaveMedia"
    ></DFGalleryPictrue>
  </div>
</template>

<script>
import Quill from "quill";
import { quillEditor } from "vue-quill-editor";
import { lineHeightStyle } from "@/utils/lineHeight";

let toolbarOptions = [
  ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
  ["blockquote", "code-block"], //引用，代码块
  [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
  [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
  [{ list: "ordered" }, { list: "bullet" }], //列表
  [{ script: "sub" }, { script: "super" }], // 上下标
  [{ indent: "-1" }, { indent: "+1" }], // 缩进
  [{ lineheight: ["initial", "1", "1.5", "1.75", "2", "3", "4", "5"] }],
  [{ direction: "rtl" }], // 文本方向
  [{ size: ["small", false, "large", "huge"] }], // 字体大小
  [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
  [{ font: [] }], //字体
  [{ align: [] }], //对齐方式
  // ["image", "video"],
  ["gallerypictrue"],
  ["video"],
  ["clean"], //清除字体样式
];
export default {
  components: { quillEditor },

  props: {
    title: {
      type: String,
      default: "编辑备注信息",
    },
  },

  data() {
    return {
      editIndex: 0, // 编辑器鼠标光标下标
      note: "", // 备注文本
      options: {
        // VueQuillEditor配置
        placeholder: "请在这里输入",
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              lineheight: (value) => {
                if (value) {
                  let quill = this.$refs.myQuillEditor.quill;
                  quill.format("lineHeight", value);
                }
              },
              gallerypictrue: (value) => {
                if (value) {
                  let quill = this.$refs.myQuillEditor.quill;
                  this.editIndex = quill.getSelection().index;
                  this.$refs.DFGalleryPictrue.onShowDialog();
                }
              },
            },
          },
        },
      },
      showDrawer: false, // 抽屉显示
    };
  },

  methods: {
    // 准备富文本编辑器
    onEditorReady(event) {
      // 注册行高拓展
      Quill.register({ "formats/lineHeight": lineHeightStyle }, true);

      // 自定义编辑器拓展，目前只找到一个比较简单的方法直接给dom塞标签或图标样式
      let demo = document.querySelector(".ql-gallerypictrue");
      // demo.innerHTML = "<span>模板</span>";
      demo.className = "dfWebIcon dfWebIcon-gallery-line";

      setTimeout(() => {
        event.blur();
      }, 100);
    },

    // 编辑器焦点事件
    onEditorBlur() {
      this.$emit("onBlurText", this.note);
    },

    // 编辑器改变事件
    onEditorChange(e) {},

    // 保存图片
    onSaveMedia(event) {
      let quill = this.$refs.myQuillEditor.quill;
      // console.log(event);
      quill.insertEmbed(this.editIndex, "image", event[0].MediaUrl);
      this.onEditorBlur();
    },

    // 打开抽屉
    onShowDrawer(note = "") {
      this.note = note;
      this.showDrawer = true;
    },

    // 关闭抽屉
    onCloseDrawer() {
      this.onEditorBlur();
      this.note = ''
      this.showDrawer = false;
    },
  },
};
</script>

<style lang="less" scoped>
.quill-editor {
  width: 100%;
  height: 100vh;
  ::v-deep .ql-container {
    height: calc(100vh - 146px);
    .ql-editor {
      height: calc(100vh - 146px);
    }
  }
}
</style>
