<template>
  <div class="df-exchange-goods">
    <!-- start 会员卡选择对话框 -->
    <el-dialog
      title="选择会员卡"
      :visible.sync="showDialog"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <!-- start 自定义内容 -->
      <div class="content-box">
        <div class="df-row">
          <!-- 关键字输入框 -->
          <div class="df-col">
            <el-input
              v-model="submitData.word"
              size="mini"
              clearable
              placeholder="物品编号或名称"
            ></el-input>
          </div>

          <!-- 查询按钮 -->
          <div class="df-col">
            <el-button type="primary" size="mini" @click="onSearch"
              >查询</el-button
            >
          </div>
        </div>

        <el-table :data="dataList" max-height="460" size="mini" border stripe>
          <el-table-column
            prop="GoodsNo"
            label="物品编号"
            width=""
          ></el-table-column>
          <el-table-column
            prop="GoodsName"
            label="物品名称"
            width="210"
          ></el-table-column>
          <el-table-column
            prop="GoodsPrice"
            label="物品单价"
            width=""
          ></el-table-column>
          <el-table-column
            prop="UnitsName"
            label="物品单位"
            width=""
          ></el-table-column>
          <el-table-column
            prop="ExchInte"
            label="单耗积分"
            width=""
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scoped">
              <el-button
                type="primary"
                size="mini"
                @click="onSelectItem(scoped.row)"
                >选择</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- end 自定义内容 -->

      <!-- start 脚部 -->
      <span slot="footer" class="dialog-footer">
        <!-- start 分页器 -->
        <el-pagination
          background
          :page-sizes="[10, 15, 20, 25]"
          :page-size="submitData.page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalRecord"
          :pager-count="5"
          size="small"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
        <!-- end 分页器 -->

        <div class="btn-box">
          <el-button size="small" @click="onCloseDialog">取 消</el-button>
        </div>
      </span>
      <!-- end 脚部 -->
    </el-dialog>
    <!-- end 会员卡选择对话框 -->
  </div>
</template>

<script>
import Integral from "@/api/integral.js";

export default {
  data() {
    return {
      totalRecord: 0,
      submitData: {
        card_guid: "",
        word: "",
        page_index: 1,
        page_size: 10,
      },
      dataList: [],
      showDialog: false,
      loading: false,
    };
  },

  methods: {
    // 获取会员卡设置的积分物品列表
    async getGoodsByCard() {
      this.loading = true;
      try {
        let { data } = await Integral.getGoodsByCard(this.submitData);
        this.dataList = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 查询按钮点击事件
    onSearch() {
      this.submitData.page_index = 1;
      this.getGoodsByCard();
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getGoodsByCard();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getGoodsByCard();
    },

    // 选择按钮点击事件
    onSelectItem(event) {
      this.$emit("select", event);
      this.onCloseDialog();
    },

    // 重置页面数据
    resetPageData() {
      this.submitData = {
        ...this.submitData,
        word: "",
        page_index: 1,
      };
    },

    // 打开对话框
    onShowDialog(event) {
      this.submitData.card_guid = event.CardGuid;
      this.getGoodsByCard();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetPageData();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  .flex-row;
  justify-content: space-between;
}
</style>
