export default {
	
	getStorage(key){
		return JSON.parse(window.sessionStorage.getItem(key));
	},
	
	setStorage(key,value){
		window.sessionStorage.setItem(key, JSON.stringify(value));
	},
	
	removeStorage(key){
		window.sessionStorage.removeItem(key);
	},
	
	clearStorage(){
		window.sessionStorage.clear();
	}
}