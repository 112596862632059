<template>
  <div>
    <!--  -->
    <el-upload
      ref="upload"
      :action="action"
      :data="uploadData"
      :limit="limit"
      :file-list="fileList"
      :auto-upload="autoUpload"
      :list-type="listType"
      :on-change="onChange"
      :on-preview="onPreview"
      :before-remove="onBeforeRemove"
      :on-success="onSuccess"
      :on-error="onRrror"
      :on-exceed="onExceed"
      :before-upload="beforeAvatarUpload"
    >
      <i slot="default" class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: "df-upload",

  props: {
    // 上传图片url
    imageUrl: {
      type: String,
      default: "",
    },

    // 图片列表
    imageList: {
      type: Array,
      default: () => [],
    },

    // 额外带参数
    uploadData: {
      type: Object,
      default: () => {
        return {};
      },
    },

    // 上传图片数量
    limit: {
      type: Number,
      default: 1,
    },

    // 是否自动上传图片
    autoUpload: {
      type: Boolean,
      default: false,
    },

    // 图片样式
    listType: {
      type: String,
      default: "picture-card",
    },
  },

  data() {
    return {
      action: "",
      fileList: [],
      oldUrl: "",
    };
  },

  watch: {
    imageUrl(newVal, oldVal) {
      this.action = newVal;
    },

    imageList: {
      deep: true,
      handler(newVal) {
        this.fileList = newVal;
      },
    },
  },

  mounted() {},

  destroyed() {},

  methods: {
    // 初始化图片数据
    initImageList(data = []) {
      this.fileList = data;
    },

    // 改变图片
    onChange(file, fileList) {
      // console.log(file, fileList);
      if (file.status == "ready") {
        this.$emit("readyUpload", file);
      }
      this.fileList = fileList;
    },

    // 预览图片
    onPreview(file) {
      // console.log(file);
    },

    // 删除图片前
    onBeforeRemove(file, fileList) {
      if (file.status != "ready") {
        this.$emit("remove", file);
        return false;
      } else {
        this.onRemove(file);
      }
    },

    // 删除图片
    onRemove(file, fileList) {
      this.fileList.forEach((item, index) => {
        if (item.uid == file.uid) {
          this.fileList.splice(index, 1);
        }
      });
    },

    // 提交图片成功
    onSuccess(response, file, fileList) {
      console.log(response);
      // 要显示图片
      this.$emit("success", response);
    },

    // 提交图片失败
    onRrror(err, file, fileList) {
      console.log(err, file, fileList);
      this.fileList = [];
      this.$message.error("上传图片失败, 请重新上传图片");
      this.$emit("error", err);
    },

    // 超过上传图片数量
    onExceed(files, fileList) {
      this.$message.error("只能上传一张图片");
    },

    // 上传文件限制事件
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";
      const isBMP = file.type === "image/bmp";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isGIF && !isPNG && !isBMP) {
        this.$message.error("上传图片必须是JPG/GIF/PNG/BMP 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return (isJPG || isBMP || isGIF || isPNG) && isLt2M;
    },

    // 保存图片
    submitUpload(url = "") {
      if (url == "" || this.fileList.length == 0) {
        return this.$emit("success", false);
      }
      this.action = url;
      this.$nextTick(() => {
        // console.log(this.fileList.length);
        if (this.fileList.length > 0) {
          // console.log(this.$refs.upload);
          this.$refs.upload.submit();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.haed-portrait {
  position: relative;
  height: 150px;
  width: 150px;
  cursor: pointer;
  .img {
    width: 150px;
    height: 150px;
    border-radius: 6px;
    border: 1px solid #c0ccda;
    box-sizing: border-box;
  }
  .funtion {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.3);
    .el-icon-delete {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 30px;
      color: #ffffff;
    }
  }
}

.haed-portrait:hover .funtion {
  display: block;
}
</style>
