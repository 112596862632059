import { request } from "../utils/axios.js";
export default {
  // 会员 => 会员卡查询
  getVipMaintItemList(params = {}, exportXls, exportCount) {
    return request("post", `/vip/get_vip_maint_item_list${exportXls ? '?command=export_xls&export_count='+exportCount : ''}`, params);
  },

  getInitVipSelectData(params = {}) {
    return request("get", `/vip/get_init_vip_select_data`, params);
  },

  loseVip(params = {}) {
    return request("get", `/vip/lose_vip`, params);
  },

  disableVip(params = {}) {
    return request("get", `/vip/disable_vip`, params);
  },

  cancelVip(params = {}) {
    return request("get", `/vip/cancel_vip`, params);
  },

  deleteVip(params = {}) {
    return request("get", `/vip/delete_vip`, params);
  },

  recoverVip(params = {}) {
    return request("get", `/vip/recover_vip`, params);
  },

  // 会员 ===> 积分兑换记录
  initQuerySelector(params = {}) {
    return request("get", `/vip/init_query_selector`, params);
  },

  getQuerySelector(params = {}) {
    return request("get", `/vip/get_query_selector`, params);
  },

  // 会员 ===> 套卡次数转金额
  getTrunMoneyMaintList(params = {}) {
    return request("get", `/vip/get_trun_money_maint_list`, params);
  },

  reverseTrunMoney(params = {}) {
    return request("get", `/vip/reverse_trun_money`, params);
  },

  // 会员 ===> 会员退款
  getRefundMaintList(params = {}) {
    return request("get", `/vip/get_refund_maint_list`, params);
  },

  reverseRefund(params = {}) {
    return request("get", `/vip/reverse_refund`, params);
  },

  alterRefundSoftwareDate(params = {}) {
    return request("post", `/vip/alter_refund_software_date`, params);
  },

  // 会员 ===> 套卡和赠送项目
  initVipItemQuery(params = {}) {
    return request("get", `/vip/init_vip_item_query`, params);
  },

  getVipPackItemList(params = {}) {
    return request("get", `/vip/get_vip_pack_item_list`, params);
  },

  getVipDonaItemList(params = {}, exportXls, exportCount) {
    return request("get", `/vip/get_vip_dona_item_list${exportXls ? '?command=export_xls&export_count='+exportCount : ''}`, params);
  },

  // 会员 ===> 套卡次数删除
  getVipitemReduceMaintList(params = {}) {
    return request("get", `/vip/get_vipitem_reduce_maint_list`, params);
  },

  reverseVipitemReduce(params = {}) {
    return request("get", `/vip/reverse_vipitem_reduce`, params);
  },
};
