<template>
  <!-- 门店选择器 -->
  <div class="df-store-select">
    <el-select
      :style="{ width }"
      v-model="rangeStoresGuids"
      :multiple="multiple"
      :collapse-tags="collapseTags"
      :clearable="clearable"
      size="mini"
      placeholder="请选择门店"
      @change="onChangeStores"
    >
      <el-option
        v-for="item in storesList"
        :key="item.StoresGuid"
        :label="item.StoresName"
        :value="item.StoresGuid"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    stores: {
      type: Array,
      default: () => [],
    },

    multiple: {
      type: Boolean,
      default: () => false,
    },

    clearable: {
      type: Boolean,
      default: () => false,
    },

    collapseTags: {
      type: Boolean,
      default: () => false,
    },

    width: {
      type: String,
      default: () => "100%",
    },
  },

  data() {
    return {
      storesList: [], // 门店列表
      rangeStoresGuids: [], // 门店id数组
    };
  },

  mounted() {
    this.initStoresList();
  },

  methods: {
    // 初始化门店列表数据
    initStoresList() {
      let accountInfo = window.sessionStorage.getItem("accountInfo");
      accountInfo = accountInfo ? JSON.parse(accountInfo) : {};

      let manageStoresList =
        this.stores.length > 0
          ? JSON.parse(JSON.stringify(this.stores))
          : accountInfo.manageStoresList || [];
      this.multiple && manageStoresList.length > 0
        ? manageStoresList.unshift({ StoresGuid: "", StoresName: "全部" })
        : null;
      this.storesList = manageStoresList;
      //   console.log(accountInfo);
    },

    // 改变门店选中事件
    onChangeStores(event) {
      console.log(event);

      if (this.multiple) {
        let res = event.filter((item) => item == "");
        if (res.length > 0) {
          this.rangeStoresGuids = [];
          this.storesList.forEach((e) => {
            this.rangeStoresGuids.push(e.StoresGuid);
          });
          this.rangeStoresGuids.shift();
        }

        return this.$emit("change", {
          storesList: this.stores,
          selectItems: this.rangeStoresGuids,
        });
      }

      // console.log(item);
      this.rangeStoresGuids = event;
      this.$emit('change', {
        storesList: this.stores,
        selectItems: (this.storesList.filter(e=> e.StoresGuid == event))[0]
      })
    },
  },
};
</script>

<style lang="less" scoped>
.el-select {
  width: 100%;
  max-width: 240px !important;
  margin-left: 0;
  margin-right: 10px;
  ::v-deep .el-tag {
    max-width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
