export default {
  path: "/service",
  name: "Service",
  redirect: "/service/item",
  meta: {
    title: "服务与商品",
    keepAlive: true,
  },
  component: () => import("../views/service/index.vue"),
  children: [
    /** 项目设置 */
    {
      path: "item",
      name: "Item",
      redirect: "/service/item/maintain",
      meta: {
        title: "项目设置",
        keepAlive: true,
      },
      component: () => import("../views/service/item/index.vue"),
      children: [
        {
          path: "maintain",
          name: "Maintain",
          meta: {
            title: "服务项目",
            keepAlive: true,
          },
          component: () => import("../views/service/item/maintain/list.vue"),
        },
        {
          path: "category",
          name: "Category",
          meta: {
            title: "项目分类",
            keepAlive: true,
          },
          component: () => import("../views/service/item/category/list.vue"),
        },
        {
          path: "commission",
          name: "Commission",
          meta: {
            title: "提成折扣分类",
            keepAlive: true,
          },
          component: () => import("../views/service/item/commission/commission.vue"),
        },
        {
          path: "reservation",
          name: "Reservation",
          meta: {
            title: "预约分类",
            keepAlive: true,
          },
          component: () => import("../views/service/item/reservation/list.vue"),
        },
      ],
    },
    /** 提成设置 */
    {
      path: "royalties",
      name: "Royalties",
      redirect: "/service/royalties/item",
      meta: {
        title: "提成设置",
        keepAlive: true,
      },
      component: () => import("../views/service/royalties/royalties.vue"),
      children: [
        {
          path: "item",
          name: "Item",
          meta: {
            title: "项目提成",
            keepAlive: true,
          },
          component: () =>
            import("../views/service/royalties/item/item.vue"),
        },
        {
          path: "commission",
          name: "Commission",
          meta: {
            title: "项目提成",
            keepAlive: true,
          },
          component: () =>
            import("../views/service/royalties/commission/commission.vue"),
        },
        {
          path: "time",
          name: "Time",
          meta: {
            title: "项目提成",
            keepAlive: true,
          },
          component: () => import("../views/service/royalties/time/time.vue"),
        },
        {
          path: "scope",
          name: "Scope",
          meta: {
            title: "项目提成",
            keepAlive: true,
          },
          component: () => import("../views/service/royalties/scope/scope.vue"),
        },
        // {
        //   path: "category",
        //   name: "RoyaltiesCategory",
        //   meta: {
        //     title: "分类批量设置",
        //     keepAlive: true,
        //   },
        //   component: () =>
        //     import("../views/service/royalties/category/list.vue"),
        // },
      ],
    },
  ],
};
