<template>
  <div class="df-shop-goods">
    <!-- <el-select
      popper-class="down-select"
      :popper-append-to-body="!isDialog"
      v-model="itemId"
      :multiple="selectMultiple"
      :collapse-tags="isTags"
      :size="size"
      placeholder="请选择员工"
      @focus="onShowEmplSelect"
      @remove-tag="onDeleteTag"
      @change="onChangeManager"
    >
      <el-option
        v-for="item in storeList"
        :key="item.ManagerGuid"
        :label="item.ManagerName"
        :value="item.ManagerGuid"
      >
      </el-option>
    </el-select> -->

    <el-input
    ref="input"
      v-model="goodsSelectList[0].ItemName"
      placeholder="请选择"
      :size="size"
      @focus="onShowEmplSelect"
    ></el-input>

    <DfShopGoodsDialog
      v-if="isDialog"
      ref="DfShopGoodsDialog"
      :appendToBody="appendToBody"
      :multiple="goodsMultiple"
      :isGroupon="isGroupon"
      @onConfirm="onSelectedGoods"
    ></DfShopGoodsDialog>
  </div>
</template>

<script>
import DfShopGoodsDialog from "./df-shop-goods-dialog.vue";

export default {
  components: { DfShopGoodsDialog },

  props: {
    storesGuids: {
      // 选中门店id数组
      type: Array,
      default: () => null,
    },

    emplGuids: {
      // 选中员工id数组
      type: Array,
      default: () => [],
    },

    // storeList: {
    //   // 门店员工数据
    //   type: Array,
    //   default: () => [],
    // },

    appendToBody: {
      type: Boolean,
      default: () => false,
    },

    // 是否参与拼团商品
    isGroupon: {
      type: Number,
      default: () => -1,
    },

    isTags: {
      // 是否启用标签模式
      type: Boolean,
      default: () => false,
    },

    isDialog: {
      // 是否启用对话框模式
      type: Boolean,
      default: () => true,
    },

    selectMultiple: {
      // 多选框是否多选
      type: Boolean,
      default: () => false,
    },

    goodsMultiple: {
      // 商品是否多选
      type: Boolean,
      default: () => false,
    },

    size: {
      // 选择器组件的大小
      type: String,
      default: () => "small",
    },
  },

  data() {
    return {
      authType: "", // 当前账号等级 H 总店 M 各门店
      goodsSelectList: [{
        ItemName: ''
      }], // 选中商品列表
    };
  },

  created() {
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
  },

  methods: {
    // 打开员工选择对话框
    onShowEmplSelect() {
      if (!this.isDialog) {
        return;
      }
      this.$refs.input.blur();
      // console.log(this.$refs.DfShopGoodsDialog);
      this.$refs.DfShopGoodsDialog.onShowDialog();
    },

    // 下拉框改变事件
    // onChangeManager(event) {
    //   if (this.isDialog) {
    //     return;
    //   }
    //   this.returnBack({ emplGuids: [event], storeList: [] });
    // },

    // 商品选中事件
    onSelectedGoods(event) {
      this.goodsSelectList = event.selectItems;
      this.returnBack({ goodsSelect: event.selectItems });
      // console.log(this.goodsSelectList);
    },

    // 员工选择器tag删除事件
    // onDeleteTag() {
    //   let storeList = JSON.parse(JSON.stringify(this.storeList));
    //   let emplGuids = JSON.parse(JSON.stringify(this.emplGuids));

    //   for (let i = 0; i < storeList.length; i++) {
    //     for (let j = 0; j < storeList[i].DutyList.length; j++) {
    //       for (let z = 0; z < storeList[i].DutyList[j].EmplList.length; z++) {
    //         if (storeList[i].DutyList[j].EmplList[z].check) {
    //           storeList[i].DutyList[j].EmplList[z].check = 0;

    //           let checkLength = storeList[i].DutyList[j].EmplList.filter(
    //             (emplItem) => emplItem.check
    //           ).length;
    //           // let emplLength = storeList[i].DutyList[j].EmplList.length;

    //           storeList[i].DutyList[j].isIndeterminate = checkLength
    //             ? true
    //             : false;
    //           storeList[i].DutyList[j].checkAll = false;
    //           storeList[i].activeNumber--;

    //           //   this.storeList = storeList;
    //           //   this.emplGuids.splice(0, 1);
    //           emplGuids.splice(0, 1);
    //           this.returnBack({ emplGuids, storeList });
    //           // console.log(
    //           //   this.submitData.EmplGuids,
    //           //   this.submitData.EmplGuids.length
    //           // );
    //           return;
    //         }
    //       }
    //     }
    //   }
    // },

    // 父组件通信
    returnBack(param = { goodsSelect: [] }) {
      this.$emit("selected", param);
    },
  },
};
</script>

<style lang="less" scoped>
.el-tag {
  display: flex;
  align-items: center;
  .el-select__tags-text {
    display: inline-block;
    max-width: 85px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
