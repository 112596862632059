import Vue from "vue";
import Vuex from "vuex";
import utils from "@/utils/utils.js";
import Common from "@/api/common.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    beginDay: "", // 开始日期 YYYY-mm-dd
    endDay: "", // 结束日期
    beginMonth: "", // 开始月份 YYYY-mm
    endMonth: "", // 结束月份
  },

  mutations: {},

  actions: {
    initRxcode({ commit }, callback) {
      Common.getNewRxcode()
        .then((res) => {
          window.localStorage.setItem("rxcode", res.data);
          if (callback) {
            callback();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onChangeDateState({state}, { dayTime, monthTime }) {
      if (Array.isArray(dayTime) && dayTime.length > 0) {
        state.beginDay = dayTime[0];
        state.endDay = dayTime[1];
        state.beginMonth = dayTime[0].slice(0, 7);
        state.endMonth = dayTime[1].slice(0, 7);
      } else if (typeof dayTime === "string" && dayTime != "") {
        state.beginDay = dayTime;
        state.endDay = dayTime;
        state.beginMonth = dayTime.slice(0, 7);
        state.endMonth = dayTime.slice(0, 7);
      } else if (Array.isArray(monthTime) && monthTime.length > 0) {
        state.beginMonth = monthTime[0];
        state.endMonth = monthTime[1];
        state.beginDay = monthTime[0] + "-01";
        console.log(monthTime);
        console.log(parseInt(monthTime[1].split("-")[0], 10), parseInt(monthTime[1].split("-")[1], 10));
        state.endDay = utils.dateFormat(
          "YYYY-mm-dd",
          new Date(
            parseInt(monthTime[1].split("-")[0], 10),
            parseInt(monthTime[1].split("-")[1], 10),
            0
          )
        );
      } else if (typeof monthTime === "string" && monthTime != "") {
        state.beginMonth = monthTime;
        state.endMonth = monthTime;
        state.beginDay = monthTime + "-01";
        state.endDay = utils.dateFormat(
          "YYYY-mm-dd",
          new Date(
            parseInt(monthTime.split("-")[0], 10),
            parseInt(monthTime.split("-")[1], 10),
            0
          )
        );
      }

      window.sessionStorage.setItem("dateState", JSON.stringify(state));
    },

    onGetDateState({state}) {
      let { beginDay, endDay, beginMonth, endMonth } = JSON.parse(window.sessionStorage.getItem('dateState') || '{}')
      return {
        beginDay: state.beginDay || beginDay || utils.getNowMonthToday(),
        endDay: state.endDay || endDay || utils.getNowMonthToday(),
        beginMonth: state.beginMonth || beginMonth || utils.getMonth(),
        endMonth: state.endMonth || endMonth || utils.getMonth(),
      };
    },

    onRemoveDateState({state}) {
      state.beginDay = "";
      state.endDay = "";
      state.beginMonth = "";
      state.endMonth = "";
      window.sessionStorage.removeItem("dateState");
    },
  },

  modules: {},
});
