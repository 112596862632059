<template>
  <div class="df-handle-pressing">
    <el-dialog
      title="处理内容"
      :visible.sync="showDialog"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div class="handle-info">
        <el-form label-width="100px" :inline="false" size="mini">
          <el-form-item label="处理人">
            <el-select
              v-model="submitData.HandlerGuid"
              placeholder="请选择处理人"
              clearable
              filterable
              size="small"
              @change="onChangeHandler"
            >
              <el-option
                v-for="item in handlerList"
                :key="item.ManagerGuid"
                :label="item.ManagerName"
                :value="item.ManagerGuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="处理完成时间">
            <el-date-picker
              v-model="submitData.HandleTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              size="small"
              :default-time="tempDefaultDate"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="处理方式">
            <el-select
              v-model="submitData.HandleMode"
              placeholder="请选择处理方式"
              clearable
              size="small"
              @clear="submitData.HandleMode = ''"
            >
              <el-option
                v-for="item in handleModeList"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="处理结果">
            <el-input
              v-model="submitData.HandleResult"
              type="textarea"
              show-word-limit
              :maxlength="200"
              :rows="8"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer">
        <el-button size="small" @click="onCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          size="small"
          :disabled="submitDisabled"
          @click="submitHandleMessage"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Aep from "@/api/aep.js";

export default {
  data() {
    return {
      tempDefaultDate: "",
      submitData: {
        MsgId: "",
        HandlerGuid: "",
        HandlerName: "",
        HandleTime: "",
        HandleMode: "",
        HandleResult: "",
      },
      accountInfo: {},
      handlerList: [],
      handleModeList: [],
      showDialog: false,
      submitDisabled: false,
    };
  },

  mounted() {
    this.accountInfo = this.storage.getStorage("accountInfo");
  },

  methods: {
    // 初始化消息处理详情
    async initHandleMessage() {
      try {
        let { data } = await Aep.initHandleMessage();

        this.handleModeList = data.handleModeList;
        this.handlerList = data.handlerList;

        let arr = data.handlerList.filter(
          (item) => item.ManagerGuid === this.accountInfo.authMapGuid
        );
        this.submitData.HandlerGuid = arr[0].ManagerGuid;
        this.submitData.HandlerName = arr[0].ManagerName;
      } catch (err) {
        console.log(err);
      }
    },

    // 提交消息处理
    async submitHandleMessage() {
      let { submitData } = this;
      if (!submitData.HandlerGuid) {
        return this.$message.error("请选择处理人");
      } else if (!submitData.HandleTime) {
        return this.$message.error("请选择处理完成时间");
      } else if (submitData.HandleMode === '') {
        return this.$message.error("请选择处理方式");
      } else if(submitData.HandleResult === ''){
        return this.$message.error("请输入处理结果");
      }
      this.submitDisabled = true;
      
      try {
        let { errcode } = await Aep.submitHandleMessage(submitData);
        if (errcode === 0) {
          this.$message.success("处理成功");
          this.$emit("success");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.submitDisabled = false;
      }
    },

    // 负责人改变事件
    onChangeHandler(event) {
      let arr = this.handlerList.filter((item) => item.ManagerGuid === event);
      this.submitData.HandlerName = arr[0].ManagerName;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },

    // 显示对话框
    onShowDialog(msgId = "") {
      let nowTime = this.util.dateFormat("YYYY-mm-dd", new Date());
      let nowDate = this.util.dateFormat("HH:MM:SS", new Date());
      this.submitData.HandleTime = nowTime + " " + nowDate;
      this.tempDefaultDate = nowDate;
      this.submitData.MsgId = JSON.parse(JSON.stringify(msgId));
      this.initHandleMessage();
      this.showDialog = true;
    },
  },
};
</script>

<style lang="less" scoped></style>
