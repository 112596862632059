<template>
	<div>
		<el-input
			v-model="inputText"
			:disabled="isDisabled"
			@blur="onBlur" 
			@focus="onFocus" 
			:placeholder="placeholder">
		</el-input>
	</div>
</template>

<script>
	export default {
		// 定义model
		model: {  
			prop: 'inputValue',  
			event: 'onBlur'  
		},
		
		props: {
			inputValue: {
				type: [Number, String],
				default: ''
			},
			
			isFocusClearInput: {
				type: Boolean,
				default: false
			},
			
			isDisabled: {
				type: Boolean,
				default: false
			},
			
			placeholder: {
				type: String,
				default: ''
			}
		},
		
		watch: {
			inputValue: {
				immediate:true,
				handler(newVal,oldV){
					this.inputText = newVal == -1 ? '' : newVal;
				}
			}
		},
		
		data(){
			return {
				inputText: ''
			}
		},
		
		methods: {
			// 获取焦点事件
			onFocus(){
				if(this.inputText == -1 && this.isFocusClearInput){
					this.inputText = '';
				}
			},
			
			// 失去焦点事件
			onBlur(){
				let result = this.inputText == '' ? -1 : parseFloat(this.inputText);
				this.$emit('onBlur', result);
				this.$emit('blur', result);
				if(this.inputText == -1){
					this.inputText = '';
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	
</style>