export default {
  path: "/marketing",
  name: "Marketing",
  redirect: "/marketing/preview",
  meta: {
    title: "营销",
    keepAlive: true,
  },
  component: () => import("../views/marketing/index.vue"),
  children: [
    {
      path: "preview",
      name: "Preview",
      meta: {
        title: "营销",
        keepAlive: true,
      },
      component: () => import("../views/marketing/preview.vue"),
    },
    {
      path: "tracking",
      name: "Tracking",
      meta: {
        title: "服务跟踪回访",
        keepAlive: true,
      },
      component: () => import("../views/marketing/tracking/tracking.vue"),
    },
    {
      path: "lost",
      name: "Lost",
      meta: {
        title: "流失挽回",
        keepAlive: true,
      },
      component: () => import("../views/marketing/lost/lost.vue"),
    },
    {
      path: "consume-manage",
      name: "ConsumeManage",
      meta: {
        title: "周期管理",
        keepAlive: true,
      },
      component: () =>
        import("../views/marketing/consume-manage/consume-manage.vue"),
    },
    {
      path: "birthday",
      name: "Birthday",
      meta: {
        title: "生日营销",
        keepAlive: true,
      },
      component: () => import("../views/marketing/birthday/birthday.vue"),
    },
    {
      path: "inte-exch/inte-exch-record",
      name: "InteExchRecord",
      meta: {
        title: "积分兑换记录",
        keepAlive: true,
      },
      component: () =>
        import(
          "../views/marketing/inte-exch/inte-exch-record/inte-exch-record.vue"
        ),
    },
    {
      path: "inte-exch/inte-exch-goods-list",
      name: "InteExchGoodsList",
      meta: {
        title: "积分兑换物品",
        keepAlive: true,
      },
      component: () =>
        import(
          "../views/marketing/inte-exch/inte-exch-goods-list/inte-exch-goods-list.vue"
        ),
    },
    {
      path: "distribution/distributor",
      name: "Distributor",
      meta: {
        title: "分销商",
        keepAlive: true,
      },
      component: () =>
        import(
          "../views/marketing/distribution/distributor/distributor.vue"
        ),
    },
    {
      path: "distribution/orders",
      name: "Orders",
      meta: {
        title: "分销订单",
        keepAlive: true,
      },
      component: () =>
        import(
          "../views/marketing/distribution/orders/orders.vue"
        ),
    },
    {
      path: "distribution/withdraw",
      name: "Setting",
      meta: {
        title: "提现管理",
        keepAlive: true,
      },
      component: () =>
        import(
          "../views/marketing/distribution/withdraw/withdraw.vue"
        ),
    },
    {
      path: "distribution/setting",
      name: "Setting",
      meta: {
        title: "分销设置",
        keepAlive: true,
      },
      component: () =>
        import(
          "../views/marketing/distribution/setting/setting.vue"
        ),
    },
    {
      path: "rebates",
      name: "Rebates",
      meta: {
        title: "分享折扣顾客裂变",
        keepAlive: true,
      },
      component: () => import("../views/marketing/coupon/coupon.vue"),
    },
    {
      path: "coupon",
      name: "Coupon",
      meta: {
        title: "优惠券营销",
        keepAlive: true,
      },
      component: () => import("../views/marketing/coupon/coupon.vue"),
    },
    {
      path: "expiration",
      name: "Expiration",
      meta: {
        title: "权益到期"
      },
      component: () => import("../views/marketing/expiration/expiration.vue"),
    }
  ],
};
