import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import storage from "./utils/storage.js";
import util from "./utils/utils.js";
import { VueJsonp } from "vue-jsonp";
import VueClipboard from "vue-clipboard2";

// import 'amfe-flexible'
// import 'lib-flexible/flexible.js';
// import './utils/rem.js';
// import SocketIO from "vue-socket.io";
// import ClientSocketIO from "socket.io-client";

import "./assets/css/update-element-ui.css";
import "./assets/css/public.css";
import "./assets/css/cashier.css";
import "./assets/css/reset-element-ui.css";
import { Loading } from "element-ui";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import DFUpload from "./components/df-upload/df-upload.vue";
import DFInput from "./components/df-input/df-input.vue";
import DFSelectItem from "./components/df-select-item/df-select-item.vue";
import DFGalleryPictrue from "./components/df-gallery-pictrue/df-gallery-pictrue.vue";
import DfQuilEditorDrawer from "./components/df-quill-editor-drawer/df-quill-editor-drawer.vue";
import DfEmplSelect from "./components/df-empl-select/df-empl-select.vue";
import DfSmsTemplate from "./components/df-sms-template/df-sms-template.vue";
import DfStoreSelect from "./components/df-store-select/df-store-select.vue";
import DfGoodsSelect from "./components/df-goods-select/df-goods-select.vue";
import DfGoodsDialog from "./components/df-goods-select/df-goods-dialog.vue";
import DfExchangeGoodsDialog from "./components/df-goods-select/df-exchange-goods-dialog.vue";
import DfCardDialog from "./components/df-card-select/df-card-dialog.vue";
import DfMemberCardDialog from "./components/df-member-card-select/df-member-card-dialog.vue";
import DfMember from "@/components/df-member/df-member.vue"
import DfHandlePressing from "@/components/df-handle-pressing/df-handle-pressing.vue"
import DfExportButton from "@/components/df-export-button/df-export-button.vue"
import DfWxmapDialog from "@/components/df-wxmap-dialog/df-wxmap-dialog.vue"
import DfShopGoods from "@/components/df-shop-goods/df-shop-goods.vue"
import DfShopGoodsDialog from "@/components/df-shop-goods/df-shop-goods-dialog.vue"
import DfPathTipsDialog from "@/components/df-path-tips-dialog/df-path-tips-dialog.vue"
import DfSyncStoreDialog from "@/components/df-sync-store-dialog/df-sync-store-dialog.vue"
import DfGetAuthKey from "@/components/df-get-auth-key/df-get-auth-key.vue"

// 引入并使用elementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
Vue.prototype.$loading = Loading;

// 引入echarts 库
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

// 引入awe-dnd 拖动
import VueDND from "awe-dnd";
Vue.use(VueDND);

// 引入 Sortablejs 库
import Sortable from "sortablejs";
Vue.prototype.Sortable = Sortable;

// 引入公共样式
import Print from "./utils/print.js";

Vue.use(Print);

Vue.use(VueJsonp);

Vue.use(VueClipboard);

// Vue.use(
//   new SocketIO({
//     debug: false, //开启调试模式
//     connection: ClientSocketIO.connect("https://api.difengsoft.com/signalr-pro/hubs/device", {
//       transports: ["websocket"], //默认使用的请求方式
//       autoConnect: false, //是否自动连接
//     }),
//   })
// );

Vue.component("DFUpload", DFUpload);
Vue.component("DFInput", DFInput);
Vue.component("DFSelectItem", DFSelectItem);
Vue.component("DFGalleryPictrue", DFGalleryPictrue);
Vue.component("DfQuilEditorDrawer", DfQuilEditorDrawer);
Vue.component("DfEmplSelect", DfEmplSelect);
Vue.component("DfSmsTemplate", DfSmsTemplate);
Vue.component("DfStoreSelect", DfStoreSelect);
Vue.component("DfGoodsSelect", DfGoodsSelect);
Vue.component("DfGoodsDialog", DfGoodsDialog);
Vue.component("DfExchangeGoodsDialog", DfExchangeGoodsDialog);
Vue.component("DfCardDialog", DfCardDialog);
Vue.component("DfMemberCardDialog", DfMemberCardDialog);
Vue.component("DfMember", DfMember);
Vue.component("DfHandlePressing", DfHandlePressing);
Vue.component("DfExportButton", DfExportButton);
Vue.component("DfWxmapDialog", DfWxmapDialog);
Vue.component("DfShopGoods", DfShopGoods);
Vue.component("DfShopGoodsDialog", DfShopGoodsDialog);
Vue.component("DfPathTipsDialog", DfPathTipsDialog);
Vue.component("DfSyncStoreDialog", DfSyncStoreDialog);
Vue.component("DfGetAuthKey", DfGetAuthKey);

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

Vue.config.productionTip = false;

Vue.directive("focus", {
  // 当绑定元素插入到 DOM 中。
  inserted: function(el) {
    // 聚焦元素
    el.focus();
  },
});

let vueThis = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// 挂载全局缓存
Vue.prototype.storage = storage;
Vue.prototype.util = util;

// 挂载全局退出登录
Vue.prototype.logout = function() {
  vueThis.storage.clearStorage();
  window.location.reload();
};

export default vueThis;
