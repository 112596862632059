<template>
  <div>
    <el-dialog
      title="选择地址"
      :visible.sync="showDialog"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-cascader
          v-model="cascaderValue"
          :options="optionsData"
          @change="handleChange"
          size="small"
          placeholder="请选择地址"
        ></el-cascader>
        <el-input
          type="textarea"
          :rows="5"
          v-model="submitData.StoresAddress"
          class="textarea"
          placeholder="请输入详情地址"
          @blur="onBlurAddress"
        ></el-input>
        <el-input
          style="margin-top: 20px;"
          v-model="submitData.Position"
          size="small"
          placeholder="地理位置精准坐标(可选)"
        ></el-input>
        <div id="map"></div>
        <div class="refresh" @click="onRefresh">
          <i class="el-icon-refresh"></i>
          <span>刷新地图</span>
        </div>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="onSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data";

export default {
  data() {
    return {
      cascaderValue: "",
      optionsData: regionData,
      submitData: {
        StoresAddress: "",
        Position: "",
      },
      showDialog: false,
    };
  },

  mounted() {},

  methods: {
    onSave() {
      this.$emit("success", this.submitData);
      this.onCloseDialog();
    },

    // 店铺地址事件
    handleChange(event) {
      let address =
        CodeToText[event[0]] + CodeToText[event[1]] + CodeToText[event[2]];
      this.submitData.StoresAddress = address;
      this.onBlurAddress();
    },

    // 详情地址事件
    onBlurAddress(event) {
      //let http = `/api/ws/geocoder/v1/?address=${this.submitData.StoresAddress}&key=UL2BZ-4Z5WD-ONP44-HG7SB-5NJBH-TSB75`;
      this.$jsonp("https://apis.map.qq.com/ws/geocoder/v1", {
        key: "UL2BZ-4Z5WD-ONP44-HG7SB-5NJBH-TSB75",
        address: this.submitData.StoresAddress,
        output: "jsonp",
      })
        .then((res) => {
          let { result } = res;
          this.submitData.Position =
            result.location.lat + "," + result.location.lng;

          this.requestGeographicPosition(
            result.location.lat,
            result.location.lng
          );
        })
        .catch((err) => {
          this.$message.error("获取地图失败");
        });
    },

    // 设置地图
    requestGeographicPosition(position_lat, position_lng) {
      //let http = `https://apis.map.qq.com/ws/geocoder/v1/?address=`

      let then = this;
      document.getElementById("map").innerHTML = "";
      //前端定位组件
      var geolocation = new qq.maps.Geolocation(
        "UL2BZ-4Z5WD-ONP44-HG7SB-5NJBH-TSB75",
        "DiFengSoft"
      );
      var options = { timeout: 8000 };
      //获取当前所在地理位置
      geolocation.getLocation(
        (position) => {
          let lat;
          let lng;
          if (position_lat && position_lng) {
            lat = position_lat;
            lng = position_lng;
          } else {
            lat = position.lat;
            lng = position.lng;
          }
          // 定义地图中心点坐标
          var center = new window.TMap.LatLng(lat, lng);
          //创建map对象，初始化地图
          var map = new window.TMap.Map(document.getElementById("map"), {
            center: center, //设置地图中心点坐标
            zoom: 17.2, // 设置地图缩放级别
            pitch: 43.5, // 设置俯仰角
            rotation: 45, // 设置地图旋转角度
            viewMode: "2D", // 默认3D
          });
          //创建并初始化MultiMarker 点标记
          var markerLayer = new window.TMap.MultiMarker({
            map: map, //指定地图容器
            //样式定义
            styles: {
              //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
              myStyle: new window.TMap.MarkerStyle({
                width: 25, // 点标记样式宽度（像素）
                height: 35, // 点标记样式高度（像素）
                src:
                  "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png", //图片路径
                //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                anchor: { x: 16, y: 32 },
              }),
            },
            //点标记数据数组
            geometries: [
              {
                id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                styleId: "myStyle", //指定样式id
                position: new window.TMap.LatLng(lat, lng), //点标记坐标位置
                properties: {
                  //自定义属性
                  title: "marker1",
                },
              },
            ],
          });
          //点击事件
          map.on("click", (event) => {
            console.log(event);
            then
              .$jsonp("https://apis.map.qq.com/ws/geocoder/v1", {
                key: "UL2BZ-4Z5WD-ONP44-HG7SB-5NJBH-TSB75",
                location: event.poi.latLng.lat + "," + event.poi.latLng.lng,
                output: "jsonp",
              })
              .then((res) => {
                let { result } = res;
                let { address, location } = result;
                then.submitData.Position = location.lat + "," + location.lng;
                then.submitData.StoresAddress = address;
                console.log(res);
              })
              .catch((err) => {
                this.$message.error("获取地图失败");
              });
            // let http = `/api/ws/geocoder/v1/?location=${event.poi.latLng.lat},${event.poi.latLng.lng}&key=UL2BZ-4Z5WD-ONP44-HG7SB-5NJBH-TSB75`;
            // axios.get(http).then(res =>{
            // 	let { data }  = res;
            // 	let { address, location} = data.result
            // 	then.submitData.Position = location.lat + ',' + location.lng;
            // 	then.submitData.StoresAddress = address;
            // 	console.log(then.submitData.Position);
            // }).catch(function (error) {
            // 	console.log(error);
            // });
            markerLayer.updateGeometries([
              {
                id: "1",
                styleId: "myStyle",
                position: new window.TMap.LatLng(
                  event.poi.latLng.lat,
                  event.poi.latLng.lng
                ),
              },
            ]);
          });
        },
        function(err) {
          console.log(err);
        },
        options
      );
    },

    onRefresh() {
      let position = [];
      if (
        this.submitData.Position !== null ||
        this.submitData.Position !== ""
      ) {
        position = this.submitData.Position.split(",");
        this.requestGeographicPosition(position[0], position[1]);
      }
    },

    resetPageData() {
      this.submitData = {
        StoresAddress: "",
        Position: "",
      };
    },

    // 打开对话框
    onShowDialog(dataInfo = "") {
      let param = JSON.parse(JSON.stringify(dataInfo));
      let position = [];
      param == ""
        ? this.resetPageData()
        : (this.submitData = {
            StoresAddress: param.StoresAddress,
            Position: param.Position,
          });
      if (
        this.submitData.Position !== null ||
        this.submitData.Position !== ""
      ) {
        position = this.submitData.Position.split(",");
      }
      setTimeout(() => {
        this.requestGeographicPosition(position[0], position[1]);
      }, 1000);
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetPageData();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .el-cascader {
    width: 100%;
  }
  .textarea {
    display: block;
    width: 100%;
    margin-top: 18px;
  }
  #map {
    width: 100%;
    height: 250px;
    border: 1px solid #dcdfe6;
    margin-top: 20px;
  }
  .refresh {
    margin-top: 10px;
    cursor: pointer;
    i {
      font-size: 20px;
      font-weight: 400;
    }
  }
}
</style>
