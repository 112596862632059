<template>
  <div class="select-item-body">
    <!-- start 选择项目对话框 -->
    <el-dialog
      title="选择项目"
      :visible.sync="showSelectItemDialog"
      width="800px"
      append-to-body
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeSelectItemDialog"
    >
      <el-container class="select-dialog-box">
        <el-aside class="left" width="200px">
          <!-- start 搜索栏 -->
          <div class="search-box">
            <!-- 搜索输入框 -->
            <el-input
              v-model="searchText"
              size="small "
              placeholder="请输入项目名"
            ></el-input>

            <!-- 查找按钮 -->
            <el-button type="primary" size="small " @click="initChildMenusData"
              >查找</el-button
            >
          </div>
          <!-- end 搜索栏 -->

          <!-- 侧边卡类型项目栏菜单 -->
          <el-menu :default-active="asideMenuIndex" @select="onAsideMenuSelect">
            <el-menu-item
              v-for="(item, index) in asideMenuList"
              :key="index"
              :index="item.ShowGuid"
            >
              <span slot="title">{{ item.ShowName }}</span>
            </el-menu-item>
          </el-menu>
        </el-aside>

        <!-- 主布局 -->
        <el-container class="right">
          <el-main>
            <!-- start 项目分类设置的table模板 -->
            <el-table
              v-loading="loading"
              :data="childMenuList"
              height="440"
              @row-click="selectRow"
              :row-class-name="rowStyle"
            >
              <el-table-column prop="ItemNo" label="编号"></el-table-column>
              <el-table-column
                prop="ItemName"
                label="项目名称"
              ></el-table-column>
              <el-table-column prop="Price" label="单价"></el-table-column>
            </el-table>
            <!-- end 项目分类设置的table模板  -->
          </el-main>
        </el-container>
      </el-container>
    </el-dialog>
    <!-- end 选择项目对话框 -->
  </div>
</template>

<script>
import itemApi from "@/api/item.js";
export default {
  props: {
    isCancelSelected: {
      type: Boolean,
      default: () => true,
    },
  },

  data() {
    return {
      asideMenuIndex: "", // 侧边菜单栏激活下标
      searchText: "", // 查询关键词
      asideMenuList: [], // 侧边菜单栏列表
      childMenuList: [], // 右边菜单栏列表
      multipleSelection: [], // 被选择行列表
      showSelectItemDialog: false, // 对话框显示
      loading: true, // 表格加载
    };
  },

  watch: {},

  mounted() {},

  methods: {
    // 初始化菜单数据
    async initTopMenusData() {
      let submitData = { subact: "top_shows" };

      let { data } = await itemApi.quickSelector(submitData);

      data.unshift({ ShowGuid: "", ShowName: "全部项目" });
      this.asideMenuList = data;

      // this.initChildMenusData();
      // console.log(data);
    },

    // 初始化项目数据
    async initChildMenusData() {
      let submitData = {
        subact: "show_items",
        show_guid: this.asideMenuIndex,
        word: this.searchText,
      };
      this.loading = true;

      let { data } = await itemApi.quickSelector(submitData);
      this.childMenuList = data;

      this.checkSelected();

      this.loading = false;
    },

    // 检查被选择行的激活状态
    checkSelected() {
      this.childMenuList = this.childMenuList.filter((item, index) => {
        item.active = false;
        for (let i = 0; i < this.multipleSelection.length; i++) {
          if (this.multipleSelection[i] == item.ItemGuid) {
            item.active = true;
            break;
          }
        }
        return item;
      });
    },

    // 侧边菜单切换事件
    onAsideMenuSelect(key, keyPath) {
      this.asideMenuIndex = key;
      this.initChildMenusData();
    },

    // 当前行被选择事件
    selectRow(event) {
      let itemIndex;
      let param = this.multipleSelection.filter((item, index) => {
        if (item == event.ItemGuid) {
          itemIndex = index;
          return item;
        }
      });

      if (param == "") {
        this.multipleSelection.push(event.ItemGuid);
        this.$emit("onItemSelected", event);
        this.checkSelected();
      } else if (this.isCancelSelected) {
        this.$message.warning("你已经选过，不能再选了");
      } else {
        this.multipleSelection.splice(itemIndex, 1);
        this.$emit("onItemSelected", "");
        this.checkSelected();
      }
    },

    // 表格row-class-name事件
    rowStyle({ row, rowIndex, columnIndex }) {
      // console.log(row.active);
      if (row.active) {
        return "active";
      }
    },

    // 打开对话框
    async openSelectItemDialog(arr) {
      // console.log(arr);
      this.multipleSelection = await arr;
      this.asideMenuList != "" ? null : await this.initTopMenusData();
      await this.initChildMenusData();
      this.showSelectItemDialog = true;
    },

    // 关闭对话框
    closeSelectItemDialog() {
      this.showSelectItemDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-dialog {
  height: 500px !important;

  .select-dialog-box {
    // height: 580px;
    // border: 1px solid black;

    .left {
      position: relative;
      width: 200px !important;
      height: 100%;
      border-right: 1px solid #e6e6e6;

      .search-box {
        position: fixed;
        width: 180px;
        display: flex;
        // margin: 0 10px;
        padding: 0 10px;
        box-sizing: border-box;
        background: white;
        z-index: 100;

        .el-input {
          // width: 100px;
        }

        .el-button {
          margin-left: 10px;
        }
      }

      .el-menu {
        width: 100%;
        height: 100%;
        margin-top: 40px;
        max-height: 400px;
        border: 0;
        text-align: center;
      }
    }

    .right {
      .el-main {
        margin: 0;
        padding: 0 20px;
      }

      .active {
        color: #018beb;
        background: rgb(224, 233, 252);
      }
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0 !important;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  color: #319deb !important;
  background-color: rgb(243, 246, 252) !important;
}
</style>
