export default {
  path: "/shop",
  name: "Shop",
  redirect: "/shop/order",
  meta: {
    title: "商城",
  },
  component: () => import("../views/shop/index.vue"),
  children: [
    {
      path: "goods",
      name: "Goods",
      redirect: "/shop/goods/list",
      meta: {
        title: "商品管理",
      },
      component: () => import("../views/shop/goods/index.vue"),
      children: [
        {
          path: "list",
          name: "GoodsList",
          meta: {
            title: "商品列表",
          },
          component: () => import("../views/shop/goods/list/list.vue"),
        },
        {
          path: "category",
          name: "GoodsCategory",
          meta: {
            title: "商品分类",
          },
          component: () => import("../views/shop/goods/category/category.vue"),
        },
      ],
    },
    {
      path: "order",
      name: "Order",
      meta: {
        title: "订单管理",
      },
      component: () => import("../views/shop/order/order.vue"),
    },
    {
      path: "group",
      name: "Group",
      redirect: "/shop/group/wares",
      meta: {
        title: "拼团管理",
      },
      component: () => import("../views/shop/group/index.vue"),
      children: [
        {
          path: "wares",
          name: "Wares",
          meta: {
            title: "拼团商品",
          },
          component: () => import("../views/shop/group/wares/wares.vue"),
        },
        {
          path: "record",
          name: "Record",
          meta: {
            title: "拼团记录",
          },
          component: () => import("../views/shop/group/record/record.vue"),
        },
      ],
    },
    {
      path: "total",
      name: "Total",
      redirect: "/shop/total/overview",
      meta: {
        title: "数据统计",
      },
      component: () => import("../views/shop/total/index.vue"),
      children: [
        {
          path: "emplGroupTotal",
          name: "EmplGroupTotal",
          meta: {
            title: "员工拼团销售统计",
          },
          component: () => import("../views/shop/total/empl-group/empl-group.vue"),
        },
        {
          path: "overview",
          name: "Overview",
          meta: {
            title: "员工拼团销售统计",
          },
          component: () => import("../views/shop/total/overview/overview.vue"),
        },
      ],
    },
    {
      path: "setting",
      name: "Setting",
      redirect: "/shop/setting/basic",
      meta: {
        title: "商城设置",
      },
      component: () => import("../views/shop/setting/index.vue"),
      children: [
        {
          path: "basic",
          name: "Basic",
          meta: {
            title: "基础设置",
          },
          component: () => import("../views/shop/setting/basic/basic.vue"),
        },
        {
          path: "home",
          name: "Home",
          meta: {
            title: "主页设置",
          },
          component: () => import("../views/shop/setting/home/home.vue"),
        },
        {
          path: "sale",
          name: "Sale",
          meta: {
            title: "分享设置",
          },
          component: () => import("../views/shop/setting/sale/sale.vue"),
        },
      ],
    },
  ],
};
